import React from "react";
import { Heading } from "@hex-insights/core";
import { MarketEntryTicketForm } from "@hex-insights/roadium.shared";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { marketEntryTicketHubDetailPageInfo } from "../DetailPage/pageinfo";
import { marketEntryTicketHubCreatePageInfo } from "./pageinfo";

export function MarketEntryTicketHubCreatePage() {
	useActivePageRegistration(marketEntryTicketHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<MarketEntryTicketForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(marketEntryTicketHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading level={1}>New Market Entry Ticket</Heading>

			<MarketEntryTicketForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
