import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const permissionGroupHubPageInfo = newPageInfo({
	title: "Permission Groups",
	description: "Pages related to the Permission Group model.",
	exact: false,
	to: hubsPageInfo.to + "/permission-groups",
	path: hubsPageInfo.path + "/permission-groups",
	parent: hubsPageInfo,
});
