import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const marketEntrancePricingStructureHubPageInfo = newPageInfo({
	title: "Market Entrance Pricing Structures",
	description: "Pages related to the Market Entrance Pricing Structure model.",
	exact: false,
	to: hubsPageInfo.to + "/market-entrance-pricing-structures",
	path: hubsPageInfo.path + "/market-entrance-pricing-structures",
	parent: hubsPageInfo,
});
