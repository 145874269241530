import { MarketEntrancePricingStructure } from "@hex-insights/roadium.shared";
import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { marketEntrancePricingStructureHubPageInfo } from "../pageinfo";

type MarketEntrancePricingStructureForRouteTitle = Pick<MarketEntrancePricingStructure, "id">;

export const marketEntrancePricingStructureHubDetailPageInfo = newPageInfo({
	name: "Market Entrance Pricing Structure Detail",
	title: (e: MarketEntrancePricingStructureForRouteTitle) => e.id,
	description: "Detail page for an marketEntrancePricingStructure.",
	exact: false,
	isLeaf: true,
	to: (id: MarketEntrancePricingStructure["id"]) => marketEntrancePricingStructureHubPageInfo.to + `/${id}`,
	path: marketEntrancePricingStructureHubPageInfo.path + "/:marketEntrancePricingStructureID(\\d+)",
	parent: marketEntrancePricingStructureHubPageInfo,
	paramsType: newRouteParamsType(
		marketEntrancePricingStructureHubPageInfo.paramsType,
		"marketEntrancePricingStructureID",
	),
});
