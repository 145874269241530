import { Column } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { Main, TicketsSummary } from "../../Components";
import { DayComparison } from "./DayComparisonChart";
import { homePageInfo } from "./pageinfo";
import { RecentTickets } from "./RecentTickets";
import { RecentTicketsTimeline } from "./RecentTicketsChart";
import { UpcomingHolidays } from "./UpcomingHolidays";
import { WeatherForecast } from "./WeatherForecast";
import { Welcome } from "./Welcome";
import styles from "./styles.module.css";

export function HomePage() {
	useActivePageRegistration(homePageInfo);

	return (
		<Main className="tile-background">
			<Column justify="spaced-start">
				<Welcome />

				<Column
					justify="spaced-center"
					align="spaced-center"
					className={`${styles["tiles-container"]} ${styles["weather-holiday-container"]}`}
				>
					<WeatherForecast />
					<UpcomingHolidays />
				</Column>

				<TicketsSummary />

				<Column
					justify="spaced-center"
					align="spaced-center"
					className={`${styles["tiles-container"]} ${styles["recent-tickets-container"]}`}
				>
					<RecentTicketsTimeline />
					<RecentTickets />
				</Column>

				<DayComparison />
			</Column>
		</Main>
	);
}
