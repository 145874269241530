import { Column } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { TicketsSummary } from "../../../Components";
import { reportsIndexPageInfo } from "./pageinfo";
import { TicketCharts } from "./TicketCharts";

export function ReportsIndex() {
	useActivePageRegistration(reportsIndexPageInfo);

	return (
		<Column justify="spaced-start">
			<TicketsSummary />
			<TicketCharts />
		</Column>
	);
}
