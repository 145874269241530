import { newPageInfo } from "@hex-insights/router";
import { userHubPageInfo } from "../pageinfo";

export const userHubCreatePageInfo = newPageInfo({
	name: "New User",
	title: "New",
	description: "Create a new User record.",
	exact: true,
	to: userHubPageInfo.to + "/new",
	path: userHubPageInfo.path + "/new",
	parent: userHubPageInfo,
});
