// Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment.
import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../../root.pageinfo";

export const logoutPageInfo = newPageInfo({
	title: "Logout",
	description: "Logging out.",
	exact: true,
	path: "/logout",
	to: "/logout",
	parent: rootPageInfo,
	noSearch: true,
});
