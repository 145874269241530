// Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment.
import { Authentication } from "@hex-insights/app-modules";
import { requestAuthenticationInfo, serverRequest } from "@hex-insights/roadium.shared";

export function TrustedDeviceController() {
	return (
		<Authentication.TrustedDeviceController
			requestAuthenticationInfo={requestAuthenticationInfo}
			requestCreateTrustedDevice={requestCreateTrustedDevice}
		/>
	);
}

async function requestCreateTrustedDevice(formValues: Authentication.TrustDeviceCreateFormValues) {
	const response = await serverRequest("/auth/trust/device", { method: "POST", body: JSON.stringify(formValues) });
	return response.ok;
}
