import { newPageInfo } from "@hex-insights/router";
import { reportsPageInfo } from "../pageinfo";

export const ticketsTimelineReportPageInfo = newPageInfo({
	title: "Tickets Timeline Report",
	description: "Tickets Timeline report page.",
	exact: true,
	to: "/reports/tickets-timeline",
	path: "/reports/tickets-timeline",
	parent: reportsPageInfo,
});
