import React from "react";
import { ApolloError } from "@apollo/client";
import { Column, Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { User, UserDetailQuery, UserForm } from "@hex-insights/roadium.shared";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../../../Components";
import { userHubDetailAuthQRCodePageInfo } from "../AuthQRCode/pageinfo";
import { userHubDetailMainPageInfo } from "./pageinfo";
import { PasswordUpdater } from "./Password";
import { PermissionsOwnedByUser } from "./Permissions";

export type UserHubDetailMainPageProps = {
	id: User["id"];
	isLoading: boolean;
	user?: UserDetailQuery["user"];
	error?: ApolloError;
};

export function UserHubDetailMainPage({ id, isLoading, error, user }: UserHubDetailMainPageProps) {
	useActivePageRegistration(userHubDetailMainPageInfo);

	const { syncID, synchronize } = useSync();

	return (
		<Main>
			<Conditional>
				<If condition={isLoading}>Loading...</If>
				<If condition={error !== undefined}>Error loading user</If>
				<Else>
					{!!user && (
						<React.Fragment>
							<Heading level={1}>{user.email}</Heading>

							<Column justify="spaced-start">
								<InternalLink to={userHubDetailAuthQRCodePageInfo.to(id)}>Print Authentication QR Code</InternalLink>

								<UserForm.ControlledDetail key={syncID} user={user} onSuccess={synchronize} />

								<PermissionsOwnedByUser id={id} />

								<PasswordUpdater id={id} />
							</Column>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
