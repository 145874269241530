import { newPageInfo } from "@hex-insights/router";
import { marketEntranceHubPageInfo } from "../pageinfo";

export const marketEntranceHubIndexPageInfo = newPageInfo({
	name: "Market Entrances Index",
	title: "",
	description: "Index of Market Entrances.",
	exact: true,
	to: marketEntranceHubPageInfo.to,
	path: marketEntranceHubPageInfo.path,
	parent: marketEntranceHubPageInfo,
});
