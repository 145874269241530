import {
	Column,
	Conditional,
	Else,
	formatInt,
	formatMoney,
	Heading,
	If,
	Row,
	Section,
	toLocalDateString,
} from "@hex-insights/core";
import { useMarketEntryTicketSummaryReportQuery } from "@hex-insights/roadium.shared";
import { LabeledValue } from "../../LabeledValue";
import styles from "./styles.module.css";

export function TicketsSummary() {
	const { loading, data, error } = useCurrentTicketSummaryReportQuery();
	const reportData = data?.marketEntryTicketSummaryReport ?? null;

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Heading level={2} noMargin>
					Tickets Summary
				</Heading>
			</Section.Header>

			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error}>There was a problem loading tickets data.</If>
					<Else>
						{!!reportData && (
							<div className={styles["tickets-summary-stats-container"]}>
								<PeriodTicketsSummary
									name="Today"
									numTickets={reportData.numTicketsCurrentDate}
									numGuests={reportData.numGuestsCurrentDate}
									revenue={reportData.revenueCurrentDate}
								/>
								<PeriodTicketsSummary
									name="Last 7 Days"
									numTickets={reportData.numTicketsLast7Days}
									numGuests={reportData.numGuestsLast7Days}
									revenue={reportData.revenueLast7Days}
								/>
								<PeriodTicketsSummary
									name="Last 30 Days"
									numTickets={reportData.numTicketsLast30Days}
									numGuests={reportData.numGuestsLast30Days}
									revenue={reportData.revenueLast30Days}
								/>
							</div>
						)}
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

type PeriodTicketsSummaryProps = {
	name: string;
	numTickets: number;
	numGuests: number;
	revenue: number;
};

function PeriodTicketsSummary({ name, numTickets, numGuests, revenue }: PeriodTicketsSummaryProps) {
	return (
		<Column align="center">
			<div style={{ fontSize: "1.75rem", marginBottom: "0.5rem" }}>{name}</div>
			<Row
				justify="spaced-center"
				style={{
					paddingTop: "0.5rem",
					borderTop: "1px solid var(--roadium-green)",
				}}
			>
				<LabeledValue label="Tickets" scale={1.75}>
					{formatInt(numTickets)}
				</LabeledValue>
				<LabeledValue label="Guests" scale={1.75}>
					{formatInt(numGuests)}
				</LabeledValue>
				<LabeledValue label="Revenue" scale={1.75}>
					{formatMoney(revenue, { areCents: true })}
				</LabeledValue>
			</Row>
		</Column>
	);
}

function useCurrentTicketSummaryReportQuery() {
	const date = toLocalDateString(new Date());
	return useMarketEntryTicketSummaryReportQuery({ variables: { date } });
}
