import { Holiday } from "@hex-insights/roadium.shared";
import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { holidayHubPageInfo } from "../pageinfo";

type HolidayForRouteTitle = Pick<Holiday, "name">;

export const holidayHubDetailPageInfo = newPageInfo({
	name: "Holiday Detail",
	title: (e: HolidayForRouteTitle) => e.name,
	description: "Detail page for an holiday.",
	exact: false,
	isLeaf: true,
	to: (id: Holiday["id"]) => holidayHubPageInfo.to + `/${id}`,
	path: holidayHubPageInfo.path + "/:holidayID(\\d+)",
	parent: holidayHubPageInfo,
	paramsType: newRouteParamsType(holidayHubPageInfo.paramsType, "holidayID"),
});
