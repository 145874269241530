import { newPageInfo } from "@hex-insights/router";
import { marketEntrancePricingStructureHubPageInfo } from "../pageinfo";

export const marketEntrancePricingStructureHubCreatePageInfo = newPageInfo({
	name: "New Market Entrance Pricing Structure",
	title: "New",
	description: "Create a new Market Entrance Pricing Structure record.",
	exact: true,
	to: marketEntrancePricingStructureHubPageInfo.to + "/new",
	path: marketEntrancePricingStructureHubPageInfo.path + "/new",
	parent: marketEntrancePricingStructureHubPageInfo,
});
