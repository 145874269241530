import { PermissionGroup } from "@hex-insights/roadium.shared";
import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { permissionGroupHubPageInfo } from "../pageinfo";

type PermissionGroupForRouteTitle = Pick<PermissionGroup, "name">;

export const permissionGroupHubDetailPageInfo = newPageInfo({
	name: "Permission Group Detail",
	title: (e: PermissionGroupForRouteTitle) => e.name,
	description: "Detail page for an permissionGroup.",
	exact: false,
	isLeaf: true,
	to: (id: PermissionGroup["id"]) => permissionGroupHubPageInfo.to + `/${id}`,
	path: permissionGroupHubPageInfo.path + "/:permissionGroupID(\\d+)",
	parent: permissionGroupHubPageInfo,
	paramsType: newRouteParamsType(permissionGroupHubPageInfo.paramsType, "permissionGroupID"),
});
