import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { MarketEntranceHubCreatePage } from "./CreatePage";
import { marketEntranceHubCreatePageInfo } from "./CreatePage/pageinfo";
import { MarketEntranceHubDetailPage } from "./DetailPage";
import { marketEntranceHubDetailPageInfo } from "./DetailPage/pageinfo";
import { MarketEntranceHubIndexPage } from "./IndexPage";
import { marketEntranceHubIndexPageInfo } from "./IndexPage/pageinfo";
import { marketEntranceHubPageInfo } from "./pageinfo";

export function MarketEntranceHub() {
	useActivePageRegistration(marketEntranceHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...marketEntranceHubIndexPageInfo.routeProps}>
				<MarketEntranceHubIndexPage />
			</Route>
			<Route {...marketEntranceHubCreatePageInfo.routeProps}>
				<MarketEntranceHubCreatePage />
			</Route>
			<Route {...marketEntranceHubDetailPageInfo.routeProps}>
				<MarketEntranceHubDetailPage key={marketEntranceHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
