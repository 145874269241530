import { newPageInfo } from "@hex-insights/router";
import { permissionHubPageInfo } from "../pageinfo";

export const permissionHubIndexPageInfo = newPageInfo({
	name: "Permissions Index",
	title: "",
	description: "Index of Permissions.",
	exact: true,
	to: permissionHubPageInfo.to,
	path: permissionHubPageInfo.path,
	parent: permissionHubPageInfo,
});
