import React from "react";
import {
	Conditional,
	Else,
	getWeekDay,
	Heading,
	If,
	isSameTime,
	Paragraph,
	Row,
	Section,
	Tooltip,
} from "@hex-insights/core";
import { Table } from "@hex-insights/tables";
import styles from "./styles.module.css";

type ForecastPoint = {
	day: string;
	value: number;
};

type ForecastDay = {
	day: string;
	temperature: number;
	minTemperature: number;
	maxTemperature: number;
	shortDescription: string;
	detailedDescription: string;
};

const columns = ["day", "shortDescription", "detailedDescription", "temperature", "temperatureBounds"];
const columnWidths = {
	day: "minmax(10rem, 0.5fr)",
	shortDescription: "minmax(10rem, 1fr)",
	detailedDescription: "2rem",
	temperature: "4rem",
	temperatureBounds: "5.25rem",
};

export function WeatherForecast() {
	const [isLoading, setIsLoading] = React.useState(true);
	const [forecast, setForecast] = React.useState<ForecastDay[]>([]);

	React.useEffect(() => {
		(async () => {
			setIsLoading(true);

			const endpoint = await getBaseForecastGridEndpoint();
			const minMaxData = await getMinMaxTemperatureData(endpoint);
			const forecastData = await getForecastData(endpoint);
			const fullForecast = forecastData
				.map((e, i) => ({
					...e,
					minTemperature: minMaxData.minData[i].value,
					maxTemperature: minMaxData.maxData[i].value,
				}))
				.slice(0, 7);

			setForecast(fullForecast);

			setIsLoading(false);
		})();
	}, []);

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Heading level={2} noMargin>
					Weather Forecast
				</Heading>
			</Section.Header>

			<Section.Body>
				<Conditional>
					<If condition={isLoading}>Loading...</If>
					<Else>
						<Table columns={columns} columnWidths={columnWidths}>
							<Table.Body>
								{forecast.map((e) => (
									<DayForecast
										key={e.day}
										day={e.day}
										shortDescription={e.shortDescription}
										detailedDescription={e.detailedDescription}
										temperature={e.temperature}
										minTemperature={e.minTemperature}
										maxTemperature={e.maxTemperature}
									/>
								))}
							</Table.Body>
						</Table>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

type DayForecastProps = {
	day: string;
	shortDescription: string;
	detailedDescription: string;
	temperature: number;
	minTemperature: number;
	maxTemperature: number;
};

function DayForecast({
	day,
	shortDescription,
	detailedDescription,
	temperature,
	minTemperature,
	maxTemperature,
}: DayForecastProps) {
	return (
		<Table.Row className={styles["day-forecast__row"]}>
			<Table.Cell column="day" oneLine className={styles["day-forecast__cell"]}>
				{day}
			</Table.Cell>
			<Table.Cell column="shortDescription" oneLine className={styles["day-forecast__cell"]}>
				{shortDescription}
			</Table.Cell>
			<Tooltip.Container>
				<Tooltip>
					<Tooltip.Body style={{ width: "20rem" }}>
						<Paragraph>{detailedDescription}</Paragraph>
					</Tooltip.Body>
				</Tooltip>
				<Table.Cell column="detailedDescription" oneLine className={styles["day-forecast__cell"]}>
					<span className={styles["day-forecast__info-icon"]}>i</span>
				</Table.Cell>
			</Tooltip.Container>
			<Table.Cell column="temperature" className={styles["day-forecast__cell"]}>
				{temperature}&deg;
			</Table.Cell>
			<Table.Cell column="temperatureBounds" className={styles["day-forecast__cell"]}>
				<Row justify="space-between" align="center" style={{ height: "100%" }}>
					<Row className={styles["day-forecast__temp-bound"]}>
						<span className={styles["day-forecast__temp-bound__label"]}>H:</span> {maxTemperature}&deg;
					</Row>
					<Row className={styles["day-forecast__temp-bound"]}>
						<span className={styles["day-forecast__temp-bound__label"]}>L:</span> {minTemperature}&deg;
					</Row>
				</Row>
			</Table.Cell>
		</Table.Row>
	);
}

const fallbackBaseForecastGridEndpoint = "https://api.weather.gov/gridpoints/LOX/150,37";

async function getBaseForecastGridEndpoint() {
	const response = await fetch("https://api.weather.gov/points/33.8828,-118.3208"); // using approx Roadium coordinates
	if (!response.ok) {
		return fallbackBaseForecastGridEndpoint; // use fallback (current value as of 2022/03/08)
	}
	const data = await response.json();
	return data?.properties?.forecastGridData ?? fallbackBaseForecastGridEndpoint;
}

async function getMinMaxTemperatureData(endpoint: string) {
	const response = await fetch(endpoint);
	if (!response.ok) {
		return { minData: [], maxData: [] };
	}
	const data = await response.json();

	const minData: ForecastPoint[] = [];
	for (let i = 0; i < data.properties.minTemperature.values.length; i++) {
		const row = data.properties.minTemperature.values[i];
		const date = new Date(row.validTime.split("/")[0]);
		const day = getWeekDay(date, true);
		const isToday = isSameTime(date, new Date(), "day");
		const value = celsiusToFahrenheit(row.value);
		minData.push({
			day: isToday ? "Today" : day,
			value,
		});
	}

	const maxData: ForecastPoint[] = [];
	for (let i = 0; i < data.properties.maxTemperature.values.length; i++) {
		const row = data.properties.maxTemperature.values[i];
		const date = new Date(row.validTime.split("/")[0]);
		const day = getWeekDay(date, true);
		const isToday = isSameTime(date, new Date(), "day");
		const value = celsiusToFahrenheit(row.value);
		maxData.push({
			day: isToday ? "Today" : day,
			value,
		});
	}

	return { minData, maxData };
}

function celsiusToFahrenheit(c: number) {
	return (c * 9) / 5 + 32;
}

async function getForecastData(endpoint: string) {
	const response = await fetch(endpoint + "/forecast");
	if (!response.ok) {
		return [];
	}
	const data = await response.json();

	const formattedData: Omit<ForecastDay, "minTemperature" | "maxTemperature">[] = [];
	for (let i = 0; i < data.properties.periods.length; i++) {
		const row = data.properties.periods[i];
		if (!row.isDaytime && i > 0) {
			continue;
		}
		formattedData.push({
			day: i === 0 ? row.name : row.name.slice(0, 3),
			temperature: row.temperature,
			shortDescription: row.shortForecast,
			detailedDescription: row.detailedForecast,
		});
	}
	return formattedData;
}
