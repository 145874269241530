import { useUserDetailQuery } from "@hex-insights/roadium.shared";
import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../../Components";
import { UserHubDetailAuthQRCodePage } from "./AuthQRCode";
import { userHubDetailAuthQRCodePageInfo } from "./AuthQRCode/pageinfo";
import { UserHubDetailMainPage } from "./Main";
import { userHubDetailMainPageInfo } from "./Main/pageinfo";
import { userHubDetailPageInfo } from "./pageinfo";

export function UserHubDetailPage() {
	const { userID } = userHubDetailPageInfo.useParams();

	const { loading, data, error } = useUserDetailQuery({ variables: { id: userID } });
	const user = data?.user;

	useActivePageRegistration(userHubDetailPageInfo, !!user ? userHubDetailPageInfo.title(user) : "");

	return (
		<PageRouteSwitch>
			<Route {...userHubDetailMainPageInfo.routeProps}>
				<UserHubDetailMainPage id={userID} isLoading={loading} user={user} error={error} />
			</Route>
			<Route {...userHubDetailAuthQRCodePageInfo.routeProps}>
				<UserHubDetailAuthQRCodePage id={userID} isLoading={loading} user={user} error={error} />
			</Route>
		</PageRouteSwitch>
	);
}
