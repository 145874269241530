import { newPageInfo } from "@hex-insights/router";

export const rootPageInfo = newPageInfo({
	title: "Roadium Portal",
	description: "",
	exact: false,
	to: "/",
	path: "/",
	parent: null,
});
