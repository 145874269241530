import { newPageInfo } from "@hex-insights/router";
import { permissionGroupHubPageInfo } from "../pageinfo";

export const permissionGroupHubCreatePageInfo = newPageInfo({
	name: "New Permission Group",
	title: "New",
	description: "Create a new Permission Group record.",
	exact: true,
	to: permissionGroupHubPageInfo.to + "/new",
	path: permissionGroupHubPageInfo.path + "/new",
	parent: permissionGroupHubPageInfo,
});
