import { newPageInfo } from "@hex-insights/router";
import { permissionGroupHubPageInfo } from "../pageinfo";

export const permissionGroupHubIndexPageInfo = newPageInfo({
	name: "Permission Groups Index",
	title: "",
	description: "Index of Permission Groups.",
	exact: true,
	to: permissionGroupHubPageInfo.to,
	path: permissionGroupHubPageInfo.path,
	parent: permissionGroupHubPageInfo,
});
