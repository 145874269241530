import { Heading, Row } from "@hex-insights/core";
import { NavLink, Route, useActivePageRegistration } from "@hex-insights/router";
import { Main, PageRouteSwitch } from "../../Components";
import { DailyRegisterReportPage } from "./DailyRegisterReport";
import { dailyRegisterReportPageInfo } from "./DailyRegisterReport/pageinfo";
import { ReportsIndex } from "./IndexPage";
import { reportsIndexPageInfo } from "./IndexPage/pageinfo";
import { reportsPageInfo } from "./pageinfo";
import { TicketsTimelineReportPage } from "./TicketsTimelineReport";
import { ticketsTimelineReportPageInfo } from "./TicketsTimelineReport/pageinfo";
import styles from "./styles.module.css";

export function ReportsPage() {
	useActivePageRegistration(reportsPageInfo);

	return (
		<Main className="tile-background">
			<Row
				justify="space-between"
				align="center"
				className="no-print"
				style={{ margin: "var(--heading--level-1---margin)" }}
			>
				<Heading level={1} noMargin>
					Reports
				</Heading>

				<Nav />
			</Row>

			<PageRouteSwitch>
				<Route {...reportsIndexPageInfo.routeProps}>
					<ReportsIndex />
				</Route>
				<Route {...ticketsTimelineReportPageInfo.routeProps}>
					<TicketsTimelineReportPage />
				</Route>
				<Route {...dailyRegisterReportPageInfo.routeProps}>
					<DailyRegisterReportPage />
				</Route>
			</PageRouteSwitch>
		</Main>
	);
}

function Nav() {
	return (
		<Row justify="spaced-start" className={styles["reports-nav"]}>
			<NavLink to={reportsIndexPageInfo.to} exact={reportsIndexPageInfo.exact}>
				Overview
			</NavLink>
			<NavLink to={ticketsTimelineReportPageInfo.to} exact={ticketsTimelineReportPageInfo.exact}>
				Tickets Timeline
			</NavLink>
			<NavLink to={dailyRegisterReportPageInfo.to} exact={dailyRegisterReportPageInfo.exact}>
				Daily Register
			</NavLink>
		</Row>
	);
}
