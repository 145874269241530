import { MarketEntranceStation } from "@hex-insights/roadium.shared";
import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { marketEntranceStationHubPageInfo } from "../pageinfo";

type MarketEntranceStationForRouteTitle = Pick<MarketEntranceStation, "name">;

export const marketEntranceStationHubDetailPageInfo = newPageInfo({
	name: "Market Entrance Station Detail",
	title: (e: MarketEntranceStationForRouteTitle) => e.name,
	description: "Detail page for an marketEntranceStation.",
	exact: false,
	isLeaf: true,
	to: (id: MarketEntranceStation["id"]) => marketEntranceStationHubPageInfo.to + `/${id}`,
	path: marketEntranceStationHubPageInfo.path + "/:marketEntranceStationID(\\d+)",
	parent: marketEntranceStationHubPageInfo,
	paramsType: newRouteParamsType(marketEntranceStationHubPageInfo.paramsType, "marketEntranceStationID"),
});
