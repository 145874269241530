import { newPageInfo } from "@hex-insights/router";
import { holidayHubPageInfo } from "../pageinfo";

export const holidayHubCreatePageInfo = newPageInfo({
	name: "New Holiday",
	title: "New",
	description: "Create a new Holiday record.",
	exact: true,
	to: holidayHubPageInfo.to + "/new",
	path: holidayHubPageInfo.path + "/new",
	parent: holidayHubPageInfo,
});
