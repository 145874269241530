import { newPageInfo } from "@hex-insights/router";
import { holidayHubPageInfo } from "../pageinfo";

export const holidayHubIndexPageInfo = newPageInfo({
	name: "Holidays Index",
	title: "",
	description: "Index of Holidays.",
	exact: true,
	to: holidayHubPageInfo.to,
	path: holidayHubPageInfo.path,
	parent: holidayHubPageInfo,
});
