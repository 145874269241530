// Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment.
import React from "react";
import { Authentication } from "@hex-insights/app-modules";
import { Column, Conditional, Else, Heading, If, Paragraph, Row } from "@hex-insights/core";
import { Form, SubmissionStatus, SubmitButton, TextField } from "@hex-insights/forms";
import { serverRequest } from "@hex-insights/roadium.shared";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../Components";
import { loginPageInfo } from "../Login/pageinfo";
import { recoverPageInfo } from "./pageinfo";
import styles from "../authentication.module.css";

export function RecoverPage() {
	useActivePageRegistration(recoverPageInfo);

	const [isDone, setIsDone] = React.useState(false);
	const [submittedEmail, setSubmittedEmail] = React.useState("");

	const onSuccess = React.useCallback(({ email }: Authentication.RecoverFormValues) => {
		setSubmittedEmail(email);
		setIsDone(true);
	}, []);

	return (
		<Main className={styles["auth-page__main"]}>
			<Column justify="spaced-center" align="center" className={styles["auth-page__content-container"]}>
				<Heading.H1>Recover Account</Heading.H1>

				<div>
					<Conditional>
						<If condition={isDone}>
							<Paragraph>Recovery email sent to {submittedEmail}. Please allow a few minutes for arrival.</Paragraph>

							<Paragraph>
								<InternalLink
									to={{
										pathname: loginPageInfo.to,
										state: { formValues: { email: submittedEmail } },
									}}
								>
									Return to login page
								</InternalLink>
							</Paragraph>
						</If>

						<Else>
							<RecoverForm onSuccess={onSuccess} />
						</Else>
					</Conditional>
				</div>
			</Column>
		</Main>
	);
}

type RecoverFormProps = {
	onSuccess: (formValues: Authentication.RecoverFormValues) => void;
};

function RecoverForm({ onSuccess }: RecoverFormProps) {
	const { formState, onSubmit, linkState } = Authentication.useRecover({ requestRecover, onSuccess });

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications noNavigationPrompt>
			<Column justify="spaced-start">
				<If condition={SubmissionStatus.isFailure(formState.submissionStatus)}>
					<Paragraph>{formState.formSubmissionErrors._.join(" ")}</Paragraph>
				</If>

				<TextField formState={formState} name="email" autoFocus />

				<Row justify="space-between" align="flex-end">
					<InternalLink to={{ pathname: loginPageInfo.to, state: linkState }}>Cancel</InternalLink>

					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Recover
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

function requestRecover(formValues: Authentication.RecoverFormValues) {
	return serverRequest("/auth/recover", {
		method: "POST",
		body: JSON.stringify(formValues),
	});
}
