import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { categoricalColorSchemes, defaultLineProps } from "@hex-insights/charts";
import {
	addTimeToDate,
	Button,
	CaseStyle,
	Column,
	Conditional,
	convertCase,
	dateTrunc,
	Else,
	formatDateTime,
	getObjectKeys,
	getObjectValues,
	Heading,
	If,
	preventDefault,
	Row,
	Section,
	stringToLocalDate,
	toLocalDateString,
	useUUID,
} from "@hex-insights/core";
import {
	AddSubFormButtonRenderProps,
	DateTimeField,
	FormState,
	FormType,
	RadioField,
	SubFormField,
	SubFormRenderProps,
	submissionSuccess,
	SubmitButton,
	useFormState,
	ValidationDisplayPolicy,
	ValidationStatus,
} from "@hex-insights/forms";
import {
	MarketEntryTicketTimelineReportQuery,
	useMarketEntryTicketTimelineReportQuery,
} from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import {
	formatAbbreviatedIntTick,
	formatAbbreviatedMoneyTick,
	formatDateTick,
	formatIntTick,
	formatMoneyTick,
	formatTimeOnlyLabel,
	formatTimeOnlyTick,
	getColor,
	TicketReportMetric,
	ticketReportMetricOptions,
} from "../../../Utilities";
import { ticketsTimelineReportPageInfo } from "./pageinfo";

type TicketsTimelineReportLineFormValues = {
	key: string | null;
	startTime: string | null;
	endTime: string | null;
};

type TicketsTimelineReportFormValues = {
	metric: TicketReportMetric;
	interval: string;
	lines: TicketsTimelineReportLineFormValues[];
};

const blankTicketsTimelineReportLineFormValues: TicketsTimelineReportLineFormValues = {
	key: null,
	startTime: null,
	endTime: null,
};

function getInitialTicketsTimelineReportFormValues(): TicketsTimelineReportFormValues {
	const now = new Date();

	const startTime = dateTrunc(addTimeToDate(now, [-7, "days"]), "day");
	const endTime = addTimeToDate(dateTrunc(addTimeToDate(now, [1, "day"]), "day"), [-1, "second"]);

	return {
		metric: "tickets",
		interval: "1 day",
		lines: [
			{
				key: "default",
				startTime: startTime.toISOString(),
				endTime: endTime.toISOString(),
			},
		],
	};
}

export function TicketsTimelineReportPage() {
	useActivePageRegistration(ticketsTimelineReportPageInfo);

	const formState = useFormState({
		initialFormValues: getInitialTicketsTimelineReportFormValues(),
		formType: FormType.Standing,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});

	return (
		<div>
			<Heading level={2}>Tickets Timeline Report</Heading>

			<Column justify="spaced-start">
				<TicketsTimelineReportForm formState={formState} />

				<TicketsTimelineTile formValues={formState.submittedFormValues ?? formState.initialFormValues} />
			</Column>
		</div>
	);
}

const intervalOptions = [
	{ value: "15 minutes" },
	{ value: "30 minutes" },
	{ value: "1 hour" },
	{ value: "1 day" },
	{ value: "1 week" },
	{ value: "1 month" },
	{ value: "1 quarter" },
	{ value: "1 year" },
];

const dayTypeIntervals = new Set(["1 day", "1 week", "1 month", "1 quarter", "1 year"]);

function isDayTypeInterval(interval: string) {
	return dayTypeIntervals.has(interval);
}

type TicketsTimelineReportFormProps = {
	formState: FormState<TicketsTimelineReportFormValues>;
};

function TicketsTimelineReportForm({ formState }: TicketsTimelineReportFormProps) {
	return (
		<form onSubmit={preventDefault} style={{ marginBottom: "1rem" }}>
			<Column>
				<Row justify="spaced-start">
					<RadioField
						formState={formState}
						name="metric"
						options={ticketReportMetricOptions}
						noClear
						className="fit-content"
					/>

					<RadioField formState={formState} name="interval" options={intervalOptions} noClear className="fit-content" />

					<SubFormField
						formState={formState}
						name="lines"
						label="Time Periods"
						blankItem={blankTicketsTimelineReportLineFormValues}
						hint="For intervals of 1 day or greater the timestamp is ignored and only the date is used."
						className="fit-content"
					>
						{TicketsTimelineReportLineForm}
						{AddTicketsTimelineReportLineButton}
					</SubFormField>
				</Row>

				<SubmitButton
					submissionStatus={formState.submissionStatus}
					onClick={formState.onSubmitWrapper(submitSuccessfully)}
				>
					Submit
				</SubmitButton>
			</Column>
		</form>
	);
}

function TicketsTimelineReportLineForm({
	formState,
	onRemoveClick,
}: SubFormRenderProps<TicketsTimelineReportLineFormValues>) {
	const {
		formValues: { key },
		formSetFunctions: { key: setKey },
		setFieldValidationStatus,
	} = formState;
	const uuid = useUUID();
	React.useEffect(() => {
		if (key === null) {
			setKey(uuid);
		}
		setFieldValidationStatus("key", ValidationStatus.Success);
	}, [setKey, setFieldValidationStatus, key, uuid]);

	return (
		<Row justify="spaced-start" align="flex-end" horizontalSpacing="0.5rem" style={{ marginBottom: "0.75rem" }}>
			<DateTimeField formState={formState} name="startTime" precision="minute" />
			<DateTimeField formState={formState} name="endTime" precision="minute" />
			<Button variant="secondary" size="small" onClick={onRemoveClick} style={{ marginBottom: "0.25rem" }}>
				X
			</Button>
		</Row>
	);
}

function AddTicketsTimelineReportLineButton({ onClick, disabled }: AddSubFormButtonRenderProps) {
	return (
		<Button variant="secondary" size="small" onClick={onClick} disabled={disabled}>
			Add Time Period
		</Button>
	);
}

async function submitSuccessfully() {
	return submissionSuccess();
}

type TicketsTimelineTileProps = {
	formValues: TicketsTimelineReportFormValues;
};

function TicketsTimelineTile({ formValues }: TicketsTimelineTileProps) {
	const [loadings, setLoadings] = React.useState({});
	const loading = getObjectValues(loadings).some((e) => e);
	const onLineLoadStart = React.useCallback((key: string) => {
		setLoadings((prev) => ({ ...prev, [key]: true }));
	}, []);

	const [data, setData] = React.useState({});
	const setLineData = React.useCallback((key: string, data: any) => {
		setLoadings((prev) => ({ ...prev, [key]: false }));
		setData((prev) => ({ ...prev, [key]: data }));
	}, []);

	const mergedData = React.useMemo(() => mergeTimelines(data), [data]);
	const hasData = getObjectValues(mergedData).some((e) => e.length > 0);

	const { metric, interval, lines } = formValues;

	const validLines = React.useMemo(
		() => lines.filter((e) => !(e.key === null || e.startTime === null || e.endTime === null)),
		[lines],
	);
	const isSingleLine = validLines.length === 1;

	const titleCaseMetric = convertCase(metric, CaseStyle.Title);
	const labelFormatter = React.useCallback(
		(label: string) => (isSingleLine ? formatTimestampForInterval(label, interval) : titleCaseMetric),
		[isSingleLine, interval, titleCaseMetric],
	);
	const formatXTick = isSingleLine
		? isDayTypeInterval(interval)
			? formatDateTick
			: formatTimeOnlyTick
		: returnEmptyString;
	const formatYTick = metric === "revenue" ? formatAbbreviatedMoneyTick : formatAbbreviatedIntTick;
	const tooltipFormatter = React.useCallback(
		(value: number, name: string, { payload }: { payload: MergedTimelinePoint }) => {
			const formattedValue = metric === "revenue" ? formatMoneyTick(value) : formatIntTick(value);
			const formattedName = isSingleLine
				? titleCaseMetric
				: formatTimestampForInterval(payload._timestamps[name], interval);

			return [formattedValue, formattedName];
		},
		[metric, titleCaseMetric, isSingleLine, interval],
	);

	return (
		<Section className="tile">
			<Section.Body>
				{validLines.map((e) => {
					return (
						<LineQuerier
							key={e.key}
							lineKey={e.key!}
							interval={formValues.interval}
							startTime={e.startTime!}
							endTime={e.endTime!}
							onLoadStart={onLineLoadStart}
							setData={setLineData}
						/>
					);
				})}

				<div style={{ width: "100%", height: "60vh" }}>
					<Conditional>
						<If condition={!hasData}>No data</If>
						<If condition={loading}>Loading...</If>
						<Else>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart data={mergedData[metric]}>
									<XAxis dataKey="createdAt" tickFormatter={formatXTick} />
									<YAxis tickFormatter={formatYTick} />
									<Tooltip isAnimationActive={false} labelFormatter={labelFormatter} formatter={tooltipFormatter} />

									{validLines.map((e, i) => {
										return (
											<Line
												key={e.key}
												{...defaultLineProps}
												stroke={getColor(categoricalColorSchemes.tableau10, i)}
												strokeWidth={2}
												dataKey={e.key!}
											/>
										);
									})}
								</LineChart>
							</ResponsiveContainer>
						</Else>
					</Conditional>
				</div>
			</Section.Body>
		</Section>
	);
}

function returnEmptyString() {
	return "";
}

function formatTimestampForInterval(timestamp: string, interval: string) {
	switch (interval) {
		case "15 minutes":
			return `${formatDateTime(timestamp, "MMMM Do, YYYY h:mm A")} to ${formatTimeOnlyLabel(
				addTimeToDate(timestamp, [15, "minutes"]).toISOString(),
			)}`;
		case "30 minutes":
			return `${formatDateTime(timestamp, "MMMM Do, YYYY h:mm A")} to ${formatTimeOnlyLabel(
				addTimeToDate(timestamp, [30, "minutes"]).toISOString(),
			)}`;
		case "1 hour":
			return formatDateTime(timestamp, "MMMM Do, YYYY h:mm A");
		case "1 day":
			return formatDateTime(stringToLocalDate(timestamp, "day"), "MMMM Do, YYYY");
		case "1 week":
			return formatDateTime(stringToLocalDate(timestamp, "day"), "[Week of] dddd, MMMM Do, YYYY");
		case "1 month":
			return formatDateTime(stringToLocalDate(timestamp, "day"), "MMMM YYYY");
		case "1 quarter":
			return formatDateTimeQuarter(stringToLocalDate(timestamp, "day").toISOString());
		case "1 year":
			return formatDateTime(stringToLocalDate(timestamp, "day"), "YYYY");
	}
	return timestamp;
}

function formatDateTimeQuarter(dateTime: string) {
	const year = dateTime.slice(0, 4);
	const month = dateTime.slice(5, 7);
	switch (month) {
		case "01":
		case "02":
		case "03":
			return `Q1 ${year}`;
		case "04":
		case "05":
		case "06":
			return `Q2 ${year}`;
		case "07":
		case "08":
		case "09":
			return `Q3 ${year}`;
		case "10":
		case "11":
		case "12":
			return `Q4 ${year}`;
	}
	return "";
}

type LineQuerierProps = {
	lineKey: string;
	startTime: string;
	endTime: string;
	interval: string;
	onLoadStart: (key: string) => void;
	setData: (key: string, data: MarketEntryTicketTimelineReportQuery) => void;
};

function LineQuerier({ lineKey, startTime, endTime, interval, onLoadStart, setData }: LineQuerierProps) {
	const isDayType = isDayTypeInterval(interval);
	const { loading, data } = useMarketEntryTicketTimelineReportQuery({
		variables: {
			startTime: isDayType ? toLocalDateString(new Date(startTime)) : startTime,
			endTime: isDayType ? toLocalDateString(new Date(endTime)) : endTime,
			interval,
		},
	});

	React.useEffect(() => {
		if (loading) {
			onLoadStart(lineKey);
		}
	}, [loading, onLoadStart, lineKey]);

	React.useEffect(() => {
		if (data) {
			setData(lineKey, data);
		}
	}, [data, setData, lineKey]);

	return null;
}

type MergedTimelineData = {
	[K in TicketReportMetric]: MergedTimelinePoint[];
};

type MergedTimelinePoint = {
	createdAt: string;
	_timestamps: {
		[K: string]: string;
	};
} & {
	[K: string]: number;
};

function mergeTimelines(data?: Record<string, MarketEntryTicketTimelineReportQuery>) {
	if (!data) {
		return { guests: [], tickets: [], revenue: [] };
	}

	const mergedGuests: Record<string, MergedTimelinePoint> = {};
	const mergedTickets: Record<string, MergedTimelinePoint> = {};
	const mergedRevenue: Record<string, MergedTimelinePoint> = {};

	const keys = getObjectKeys(data);
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		const timeline = data[key].marketEntryTicketTimelineReport.timeline;
		for (let j = 0; j < timeline.length; j++) {
			const point = timeline[j];
			const pointKey = j.toString();
			if (!mergedGuests[pointKey]) {
				mergedGuests[pointKey] = {
					createdAt: point.createdAt,
					_timestamps: { [key]: point.createdAt },
				} as MergedTimelinePoint;
			} else {
				mergedGuests[pointKey]._timestamps[key] = point.createdAt;
			}
			if (!mergedTickets[pointKey]) {
				mergedTickets[pointKey] = {
					createdAt: point.createdAt,
					_timestamps: { [key]: point.createdAt },
				} as MergedTimelinePoint;
			} else {
				mergedTickets[pointKey]._timestamps[key] = point.createdAt;
			}
			if (!mergedRevenue[pointKey]) {
				mergedRevenue[pointKey] = {
					createdAt: point.createdAt,
					_timestamps: { [key]: point.createdAt },
				} as MergedTimelinePoint;
			} else {
				mergedRevenue[pointKey]._timestamps[key] = point.createdAt;
			}

			mergedGuests[pointKey][key] = point.numGuests;
			mergedTickets[pointKey][key] = point.numTickets;
			mergedRevenue[pointKey][key] = point.revenue;
		}
	}

	const merged: MergedTimelineData = {
		guests: getObjectValues(mergedGuests),
		tickets: getObjectValues(mergedTickets),
		revenue: getObjectValues(mergedRevenue),
	};

	return merged;
}
