import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { PermissionHubDetailPage } from "./DetailPage";
import { permissionHubDetailPageInfo } from "./DetailPage/pageinfo";
import { PermissionHubIndexPage } from "./IndexPage";
import { permissionHubIndexPageInfo } from "./IndexPage/pageinfo";
import { permissionHubPageInfo } from "./pageinfo";

export function PermissionHub() {
	useActivePageRegistration(permissionHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...permissionHubIndexPageInfo.routeProps}>
				<PermissionHubIndexPage />
			</Route>
			<Route {...permissionHubDetailPageInfo.routeProps}>
				<PermissionHubDetailPage key={permissionHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
