import React from "react";
import { Heading } from "@hex-insights/core";
import { MarketEntrancePricingStructureForm } from "@hex-insights/roadium.shared";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { marketEntrancePricingStructureHubDetailPageInfo } from "../DetailPage/pageinfo";
import { marketEntrancePricingStructureHubCreatePageInfo } from "./pageinfo";

export function MarketEntrancePricingStructureHubCreatePage() {
	useActivePageRegistration(marketEntrancePricingStructureHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<MarketEntrancePricingStructureForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(marketEntrancePricingStructureHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading level={1}>New Market Entrance Pricing Structure</Heading>

			<MarketEntrancePricingStructureForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
