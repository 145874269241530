// Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment.
import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const hubsDetailRedirectPageInfo = newPageInfo({
	name: "Hub Detail Redirect",
	title: "Redirecting",
	description: "Redirect to an object by ID.",
	exact: true,
	to: (id: string) => hubsPageInfo.to + `/${id}`,
	path: hubsPageInfo.path + "/:id(\\d+)",
	parent: hubsPageInfo,
	noSearch: true,
	paramsType: newRouteParamsType(hubsPageInfo.paramsType, "id"),
});
