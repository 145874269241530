import { User } from "@hex-insights/roadium.shared";
import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { userHubPageInfo } from "../pageinfo";

type UserForRouteTitle = Pick<User, "email">;

export const userHubDetailPageInfo = newPageInfo({
	name: "User Detail",
	title: (e: UserForRouteTitle) => e.email,
	description: "Detail page for an user.",
	exact: false,
	isLeaf: true,
	to: (id: User["id"]) => userHubPageInfo.to + `/${id}`,
	path: userHubPageInfo.path + "/:userID(\\d+)",
	parent: userHubPageInfo,
	paramsType: newRouteParamsType(userHubPageInfo.paramsType, "userID"),
});
