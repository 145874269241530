import React from "react";
import { Column, Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { PermissionForm, usePermissionDetailQuery } from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { permissionHubDetailPageInfo } from "./pageinfo";
import { UsersWithPermission } from "./Users";

export function PermissionHubDetailPage() {
	const { permissionID } = permissionHubDetailPageInfo.useParams();

	const { loading, data, error } = usePermissionDetailQuery({ variables: { id: permissionID } });
	const { syncID, synchronize } = useSync();

	const permission = data?.permission;

	useActivePageRegistration(
		permissionHubDetailPageInfo,
		!!permission ? permissionHubDetailPageInfo.title(permission) : "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading permission</If>
				<Else>
					{!!permission && (
						<React.Fragment>
							<Heading level={1}>{permission.name}</Heading>

							<Column justify="spaced-start">
								<PermissionForm.ControlledDetail key={syncID} permission={permission} onSuccess={synchronize} />

								<UsersWithPermission id={permissionID} />
							</Column>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
