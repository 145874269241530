import { newPageInfo } from "@hex-insights/router";
import { marketEntrancePricingStructureHubPageInfo } from "../pageinfo";

export const marketEntrancePricingStructureHubIndexPageInfo = newPageInfo({
	name: "Market Entrance Pricing Structures Index",
	title: "",
	description: "Index of Market Entrance Pricing Structures.",
	exact: true,
	to: marketEntrancePricingStructureHubPageInfo.to,
	path: marketEntrancePricingStructureHubPageInfo.path,
	parent: marketEntrancePricingStructureHubPageInfo,
});
