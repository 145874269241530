import {
	addTimeToDate,
	Conditional,
	Else,
	formatDateTime,
	Heading,
	If,
	Row,
	Section,
	stringToLocalDate,
	toLocalDateString,
} from "@hex-insights/core";
import {
	Holiday,
	HolidayIndexQueryVariables,
	HolidayLink,
	HolidayOrderField,
	OrderDirection,
	useHolidayIndexQuery,
} from "@hex-insights/roadium.shared";
import { InternalLink } from "@hex-insights/router";
import { BasicTable } from "@hex-insights/tables";
import { holidayHubIndexPageInfo } from "../../Hubs/Holiday/IndexPage/pageinfo";

export function UpcomingHolidays() {
	const { loading, data, error } = useUpcomingHolidaysQuery();

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Heading level={2} noMargin>
					Upcoming Holidays
				</Heading>
			</Section.Header>

			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error}>There was a problem loading holidays.</If>
					<If condition={!!data && data.holidayConnection.edges.length === 0}>No holidays in the next month.</If>
					<Else>
						{!!data && (
							// set height to match with natural height of Weather Forecast
							<div style={{ height: "8.25rem", overflow: "scroll" }}>
								<BasicTable>
									<BasicTable.Body>
										{data.holidayConnection.edges.map((e) => (
											<HolidayRow key={e.node.id} holiday={e.node} />
										))}
									</BasicTable.Body>
								</BasicTable>
							</div>
						)}
					</Else>
				</Conditional>
			</Section.Body>

			<Section.Footer className="tile__footer">
				<Row justify="flex-end">
					<InternalLink to={holidayHubIndexPageInfo.to} style={{ fontSize: "0.9rem" }}>
						View all holidays
					</InternalLink>
				</Row>
			</Section.Footer>
		</Section>
	);
}

function useUpcomingHolidaysQuery() {
	const variables = getQueryVariables();
	return useHolidayIndexQuery({ variables });
}

function getQueryVariables(): HolidayIndexQueryVariables {
	const currentDate = new Date();
	const currentDateISO = toLocalDateString(currentDate);
	const inOneMonthISO = toLocalDateString(addTimeToDate(currentDate, [1, "month"]).toDate());

	return {
		filters: [
			{
				startDateGTE: currentDateISO,
				startDateLTE: inOneMonthISO,
			},
			{
				startDateLTE: currentDateISO,
				endDateGTE: currentDateISO,
			},
		],
		order: { field: HolidayOrderField.StartDate, direction: OrderDirection.Asc },
	};
}

type HolidayRowProps = {
	holiday: Pick<Holiday, "id" | "name" | "startDate" | "endDate" | "isMultiDay">;
};

function HolidayRow({ holiday }: HolidayRowProps) {
	return (
		<BasicTable.Row>
			<BasicTable.Cell>
				<HolidayLink instance={holiday} />
			</BasicTable.Cell>
			<BasicTable.Cell>
				{holiday.isMultiDay
					? `${formatDateShort(holiday.startDate)}-${formatDateShort(holiday.endDate)}`
					: formatDateShort(holiday.startDate)}
			</BasicTable.Cell>
		</BasicTable.Row>
	);
}

function formatDateShort(date: string) {
	return formatDateTime(stringToLocalDate(date, "day"), "M/D");
}
