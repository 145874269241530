import {
	addTimeToDate,
	Button,
	Conditional,
	dateTrunc,
	Else,
	formatDateTime,
	Heading,
	If,
	Row,
	Section,
} from "@hex-insights/core";
import {
	MarketEntryTicket,
	MarketEntryTicketFormat,
	MarketEntryTicketIndexQueryVariables,
	MarketEntryTicketOrderField,
	MarketEntryTicketVehicleType,
	OrderDirection,
	useMarketEntryTicketIndexQuery,
} from "@hex-insights/roadium.shared";
import { InternalLink } from "@hex-insights/router";
import { BasicTable } from "@hex-insights/tables";
import { marketEntryTicketHubDetailPageInfo } from "../../Hubs/MarketEntryTicket/DetailPage/pageinfo";
import { marketEntryTicketHubIndexPageInfo } from "../../Hubs/MarketEntryTicket/IndexPage/pageinfo";

export function RecentTickets() {
	const { loading, data, error, refetch } = useRecentTicketsQuery();

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Heading level={2} noMargin>
					Recent Tickets
				</Heading>
			</Section.Header>

			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={!!error}>There was a problem loading tickets.</If>
					<If condition={!!data && data.marketEntryTicketConnection.edges.length === 0}>No tickets today.</If>
					<Else>
						{!!data && (
							// set height to match with natural height of Recent Tickets Timeline chart
							<div style={{ maxHeight: "18rem", overflow: "scroll" }}>
								<BasicTable>
									<BasicTable.Body>
										{data.marketEntryTicketConnection.edges.map((e) => (
											<TicketRow key={e.node.id} ticket={e.node} />
										))}
									</BasicTable.Body>
								</BasicTable>
							</div>
						)}
					</Else>
				</Conditional>
			</Section.Body>

			<Section.Footer className="tile__footer">
				<Row justify="space-between">
					<Button
						variant="link"
						size="small"
						onClick={() => refetch()}
						style={{ fontSize: "0.9rem", padding: "0" }}
						disabled={loading}
					>
						Refresh
					</Button>
					<InternalLink to={marketEntryTicketHubIndexPageInfo.to} style={{ fontSize: "0.9rem" }}>
						View all tickets
					</InternalLink>
				</Row>
			</Section.Footer>
		</Section>
	);
}

const pollInterval = 30 * 1000;

function useRecentTicketsQuery() {
	const variables = getQueryVariables();
	return useMarketEntryTicketIndexQuery({ variables, pollInterval });
}

function getQueryVariables(): MarketEntryTicketIndexQueryVariables {
	const currentDate = dateTrunc(new Date(), "day");
	const currentDateISO = currentDate.toISOString();
	const nextDateISO = addTimeToDate(currentDate, [1, "day"]).toISOString();

	return {
		filters: [
			{
				createdAtGTE: currentDateISO,
				createdAtLT: nextDateISO,
			},
		],
		order: { field: MarketEntryTicketOrderField.CreatedAt, direction: OrderDirection.Desc },
		pagination: { first: 100 },
	};
}

type TicketRowProps = {
	ticket: Pick<
		MarketEntryTicket,
		"id" | "createdAt" | "numChildGuests" | "numRegularGuests" | "numSeniorGuests" | "vehicleType" | "amountPaid"
	>;
};

function TicketRow({ ticket }: TicketRowProps) {
	const numGuests = ticket.numChildGuests + ticket.numRegularGuests + ticket.numSeniorGuests;
	const vehiclePreposition = ticket.vehicleType === MarketEntryTicketVehicleType.Car ? "in" : "on";

	return (
		<BasicTable.Row>
			<BasicTable.Cell>{numGuests}</BasicTable.Cell>
			<BasicTable.Cell>
				{vehiclePreposition} {MarketEntryTicketFormat.Fields.vehicleType(ticket.vehicleType)}
			</BasicTable.Cell>
			<BasicTable.Cell>at {formatTimeShort(ticket.createdAt)}</BasicTable.Cell>
			<BasicTable.Cell>for {MarketEntryTicketFormat.Fields.amountPaid(ticket.amountPaid)}</BasicTable.Cell>
			<BasicTable.Cell>
				<InternalLink to={marketEntryTicketHubDetailPageInfo.to(ticket.id)}>view</InternalLink>
			</BasicTable.Cell>
		</BasicTable.Row>
	);
}

function formatTimeShort(dateTime: string) {
	return formatDateTime(dateTime, "h:mma");
}
