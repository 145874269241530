import { MarketEntrance } from "@hex-insights/roadium.shared";
import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { marketEntranceHubPageInfo } from "../pageinfo";

type MarketEntranceForRouteTitle = Pick<MarketEntrance, "name">;

export const marketEntranceHubDetailPageInfo = newPageInfo({
	name: "Market Entrance Detail",
	title: (e: MarketEntranceForRouteTitle) => e.name,
	description: "Detail page for an marketEntrance.",
	exact: false,
	isLeaf: true,
	to: (id: MarketEntrance["id"]) => marketEntranceHubPageInfo.to + `/${id}`,
	path: marketEntranceHubPageInfo.path + "/:marketEntranceID(\\d+)",
	parent: marketEntranceHubPageInfo,
	paramsType: newRouteParamsType(marketEntranceHubPageInfo.paramsType, "marketEntranceID"),
});
