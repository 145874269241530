import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, ServerError, ServerParseError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Authentication } from "@hex-insights/app-modules";
import { getUUID, isString } from "@hex-insights/core";
import * as Log from "@hex-insights/log";
import { serverURL } from "@hex-insights/roadium.shared";

const requestInfoLink = new ApolloLink((operation, forward) => {
	const requestID = getUUID();
	operation.setContext(({ headers = {} }) => ({
		startTime: performance.now(),
		requestID,
		headers: { ...headers, "X-Hex-Request-ID": requestID },
	}));

	return forward(operation).map((data) => {
		const { startTime, requestID } = operation.getContext();
		const durationMs = Math.round(performance.now() - startTime);
		Log.withFields({
			responseDurationMs: durationMs,
			requestID,
		}).info("GraphQL request");
		return data;
	});
});

const errorLoggingLink = onError(({ networkError, operation }) => {
	if (networkError) {
		const { requestID } = operation.getContext();
		const logEntry = Log.withFields({ networkError, requestID });
		if (isServerOrServerParseError(networkError)) {
			logEntry.setField("responseStatus", networkError.statusCode);
		}
		logEntry.error("GraphQL encountered a network error");
	}
});

const disabledAccountErrorMessage = "User account is disabled.";
const mfaRequiredErrorMessage = "MFA required.";

const authErrorLink = onError(({ networkError }) => {
	if (isServerOrServerParseError(networkError) && networkError.statusCode === 401) {
		Authentication.emitUnauthenticatedEvent();
	}
	if (isServerError(networkError) && networkError.statusCode === 403) {
		if (
			isString(networkError.result)
				? networkError.result === disabledAccountErrorMessage
				: networkError.result.error === disabledAccountErrorMessage
		) {
			Authentication.emitDisabledAccountEvent();
		}
		if (
			isString(networkError.result)
				? networkError.result === mfaRequiredErrorMessage
				: networkError.result.error === mfaRequiredErrorMessage
		) {
			Authentication.emitMFARequiredEvent();
		}
	}
});

const httpLink = new HttpLink({
	uri: serverURL("/graph/query"),
	credentials: "include",
});

export const client = new ApolloClient({
	link: from([requestInfoLink, errorLoggingLink, authErrorLink, httpLink]),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			errorPolicy: "all",
		},
		query: {
			errorPolicy: "all",
		},
		mutate: {
			errorPolicy: "all",
		},
	},
});

function isServerOrServerParseError(x: any): x is ServerError | ServerParseError {
	return x instanceof Error && x.hasOwnProperty("statusCode");
}

function isServerError(x: any): x is ServerError {
	return x instanceof Error && x.hasOwnProperty("result");
}
