import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { PermissionGroupHubCreatePage } from "./CreatePage";
import { permissionGroupHubCreatePageInfo } from "./CreatePage/pageinfo";
import { PermissionGroupHubDetailPage } from "./DetailPage";
import { permissionGroupHubDetailPageInfo } from "./DetailPage/pageinfo";
import { PermissionGroupHubIndexPage } from "./IndexPage";
import { permissionGroupHubIndexPageInfo } from "./IndexPage/pageinfo";
import { permissionGroupHubPageInfo } from "./pageinfo";

export function PermissionGroupHub() {
	useActivePageRegistration(permissionGroupHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...permissionGroupHubIndexPageInfo.routeProps}>
				<PermissionGroupHubIndexPage />
			</Route>
			<Route {...permissionGroupHubCreatePageInfo.routeProps}>
				<PermissionGroupHubCreatePage />
			</Route>
			<Route {...permissionGroupHubDetailPageInfo.routeProps}>
				<PermissionGroupHubDetailPage key={permissionGroupHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
