import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { categoricalColorSchemes, defaultLineProps } from "@hex-insights/charts";
import {
	addTimeToDate,
	Column,
	Conditional,
	Else,
	Heading,
	If,
	preventDefault,
	Row,
	Section,
	useHasBeenFalse,
	useInterval,
	useLazyRef,
	useSync,
} from "@hex-insights/core";
import { FormType, RadioButtonsInput, RadioField, useFormState } from "@hex-insights/forms";
import { useMarketEntryTicketTimelineReportQuery } from "@hex-insights/roadium.shared";
import {
	formatAbbreviatedIntTick,
	formatAbbreviatedMoneyTick,
	formatTimeOnlyLabel,
	formatTimeOnlyTick,
	getYDataKeyFromMetric,
	TicketReportMetric,
	ticketReportMetricOptions,
	tooltipIntFormatter,
	tooltipMoneyFormatter,
} from "../../../Utilities";
import styles from "./styles.module.css";

type RecentTicketsTimelineFormValues = {
	metric: TicketReportMetric;
};

const initialFormValues: RecentTicketsTimelineFormValues = {
	metric: "tickets",
};

export function RecentTicketsTimeline() {
	const formState = useFormState({ initialFormValues, formType: FormType.Standing });

	const initialTimestampsRef = useLazyRef(getTimeBounds);
	const { loading, data, error, refetch } = useMarketEntryTicketTimelineReportQuery({
		variables: {
			...initialTimestampsRef.current,
			interval: "1 minute",
		},
	});

	// TODO explore issues with loading/refetching/animation on chart
	const isLoading = !useHasBeenFalse(loading);
	const loadingCountRef = React.useRef(0);
	React.useEffect(() => {
		if (loading) {
			loadingCountRef.current++;
		}
	}, [loading]);
	useTicker(() => {
		refetch(getTimeBounds());
	}, 30 * 1000);

	const { metric } = formState.formValues;
	const yDataKey = getYDataKeyFromMetric(metric);
	const formatYTick = metric === "revenue" ? formatAbbreviatedMoneyTick : formatAbbreviatedIntTick;
	const tooltipFormatter = metric === "revenue" ? tooltipMoneyFormatter : tooltipIntFormatter;

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Row justify="space-between">
					<Heading level={2} noMargin>
						Recent Timeline
					</Heading>

					<form noValidate onSubmit={preventDefault}>
						<RadioField
							formState={formState}
							name="metric"
							label=""
							options={ticketReportMetricOptions}
							Input={RadioButtonsInput}
							noClear
							className={styles["recent-tickets-chart__form__metric"]}
						/>
					</form>
				</Row>
			</Section.Header>

			<Section.Body>
				<Column>
					<div style={{ width: "100%", height: "40vh", maxHeight: "20rem" }}>
						<Conditional>
							<If condition={isLoading}>Loading...</If>
							<If condition={!!error}>There was a problem loading this report.</If>
							<Else>
								<ResponsiveContainer width="99%" height="100%">
									<LineChart data={data?.marketEntryTicketTimelineReport.timeline}>
										<XAxis dataKey="createdAt" tickFormatter={formatTimeOnlyTick} />
										<YAxis dataKey={yDataKey} tickFormatter={formatYTick} />
										<Tooltip
											isAnimationActive={false}
											labelFormatter={formatTimeOnlyLabel}
											formatter={tooltipFormatter}
										/>

										<Line
											{...defaultLineProps}
											stroke={categoricalColorSchemes.tableau10[0]}
											strokeWidth={2}
											dataKey={yDataKey}
											isAnimationActive={loadingCountRef.current <= 1}
										/>
									</LineChart>
								</ResponsiveContainer>
							</Else>
						</Conditional>
					</div>
				</Column>
			</Section.Body>
		</Section>
	);
}

function useTicker<T>(f: () => T, timeout: number) {
	const { syncID, synchronize } = useSync();
	const { setInterval } = useInterval();

	React.useEffect(() => {
		setInterval(synchronize, timeout);
	}, [setInterval, synchronize, timeout]);

	return React.useMemo(f, [syncID]); // eslint-disable-line
}

function getTimeBounds() {
	const endTime = new Date();
	const startTime = addTimeToDate(endTime, [-1, "hour"]);
	return { startTime: startTime.toISOString(), endTime: endTime.toISOString() };
}
