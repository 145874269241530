import React from "react";
import {
	Bar,
	BarChart,
	Cell,
	Line,
	LineChart,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { categoricalColorSchemes, defaultBarProps, defaultLineProps, defaultPieProps } from "@hex-insights/charts";
import {
	addTimeToDate,
	ChildrenProps,
	Column,
	Conditional,
	Else,
	Heading,
	If,
	preventDefault,
	Row,
	Section,
	toLocalDateString,
} from "@hex-insights/core";
import { FormState, FormType, RadioField, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import {
	MarketEntryTicketFormat,
	MarketEntryTicketVehicleType,
	useMarketEntryTicketAggregateByDowReportQuery,
	useMarketEntryTicketAggregateByMarketEntranceReportQuery,
	useMarketEntryTicketAggregateByVehicleTypeReportQuery,
	useMarketEntryTicketTimelineReportQuery,
} from "@hex-insights/roadium.shared";
import {
	formatAbbreviatedFloatTick,
	formatAbbreviatedIntTick,
	formatAbbreviatedMoneyTick,
	formatDateLabel,
	formatDateTick,
	formatIntTick,
	formatMoneyTick,
	getColor,
	getPieLabel,
	getYDataKeyFromMetric,
	TicketReportMetric,
	ticketReportMetricOptions,
	tooltipFloatFormatter,
	tooltipIntFormatter,
	tooltipMoneyFormatter,
} from "../../../../Utilities";

type TicketChartsFormValues = {
	timeRange: number | null;
	metric: TicketReportMetric;
};

const initialTicketChartsFormValues: TicketChartsFormValues = {
	timeRange: 30,
	metric: "guests",
};

export function TicketCharts() {
	const formState = useFormState({
		initialFormValues: initialTicketChartsFormValues,
		formType: FormType.Standing,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});

	const {
		formValues: { timeRange },
	} = formState;
	const [startDate, endDate] = React.useMemo(() => {
		const endDate = new Date();
		const startDate = addTimeToDate(endDate, [-1 * ((timeRange ?? 30) - 1), "days"]).toDate();
		return [toLocalDateString(startDate), toLocalDateString(endDate)];
	}, [timeRange]);

	return (
		<Row justify="spaced-center" style={{ flexDirection: "row-reverse" }}>
			<TicketChartsForm formState={formState} />

			<Column justify="spaced-start" style={{ flexGrow: 1 }}>
				<TicketsTimeline startDate={startDate} endDate={endDate} metric={formState.formValues.metric} />
				<AggregateByDOW startDate={startDate} endDate={endDate} metric={formState.formValues.metric} />
				<AggregateByVehicleType startDate={startDate} endDate={endDate} metric={formState.formValues.metric} />
				<AggregateByMarketEntrance startDate={startDate} endDate={endDate} metric={formState.formValues.metric} />
			</Column>
		</Row>
	);
}

const timeRangeOptions = [
	{
		value: 7,
		label: "Last 7 days",
	},
	{
		value: 30,
		label: "Last 30 days",
	},
	{
		value: 90,
		label: "Last 90 days",
	},
	{
		value: 365,
		label: "Last year",
	},
];

type TicketChartsFormProps = {
	formState: FormState<TicketChartsFormValues>;
};

function TicketChartsForm({ formState }: TicketChartsFormProps) {
	return (
		<div style={{ alignSelf: "flex-start", position: "sticky", top: "calc(var(--header---height) + 0.75rem)" }}>
			<Section className="tile">
				<Section.Header className="tile__header">
					<Heading level={2} noMargin>
						Menu
					</Heading>
				</Section.Header>

				<Section.Body>
					<form onSubmit={preventDefault} style={{ "--radio-field---width": "10rem" } as React.CSSProperties}>
						<RadioField formState={formState} name="timeRange" options={timeRangeOptions} blankValue={null} noClear />
						<RadioField formState={formState} name="metric" options={ticketReportMetricOptions} noClear />
					</form>
				</Section.Body>
			</Section>
		</div>
	);
}

type TicketChartProps = {
	startDate: string;
	endDate: string;
	metric: TicketReportMetric;
};

function TicketsTimeline({ startDate, endDate, metric }: TicketChartProps) {
	const { loading, data, error } = useMarketEntryTicketTimelineReportQuery({
		variables: { startTime: startDate, endTime: endDate, interval: "1 day" },
	});

	const yDataKey = getYDataKeyFromMetric(metric);
	const formatYTick = metric === "revenue" ? formatAbbreviatedMoneyTick : formatAbbreviatedIntTick;
	const tooltipFormatter = metric === "revenue" ? tooltipMoneyFormatter : tooltipIntFormatter;

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Heading level={2} noMargin>
					Timeline
				</Heading>
			</Section.Header>

			<Section.Body>
				<ChartContainer>
					<Conditional>
						<If condition={loading}>Loading...</If>
						<If condition={!!error}>There was a problem loading this report.</If>
						<Else>
							<ResponsiveContainer width="99%" height="100%">
								<LineChart data={data?.marketEntryTicketTimelineReport.timeline}>
									<XAxis dataKey="createdAt" tickFormatter={formatDateTick} />
									<YAxis dataKey={yDataKey} tickFormatter={formatYTick} />
									<Tooltip isAnimationActive={false} labelFormatter={formatDateLabel} formatter={tooltipFormatter} />

									<Line
										{...defaultLineProps}
										stroke={categoricalColorSchemes.tableau10[0]}
										strokeWidth={2}
										dataKey={yDataKey}
									/>
								</LineChart>
							</ResponsiveContainer>
						</Else>
					</Conditional>
				</ChartContainer>
			</Section.Body>
		</Section>
	);
}

function AggregateByDOW({ startDate, endDate, metric }: TicketChartProps) {
	const { loading, data, error } = useMarketEntryTicketAggregateByDowReportQuery({
		variables: { startDate, endDate },
	});

	const yDataKey = getYDataKeyFromMetric(metric);
	const formatYTick = metric === "revenue" ? formatAbbreviatedMoneyTick : formatAbbreviatedFloatTick;
	const tooltipFormatter = metric === "revenue" ? tooltipMoneyFormatter : tooltipFloatFormatter;

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Heading level={2} noMargin>
					Daily Averages
				</Heading>
			</Section.Header>

			<Section.Body>
				<ChartContainer>
					<Conditional>
						<If condition={loading}>Loading...</If>
						<If condition={!!error}>There was a problem loading this report.</If>
						<Else>
							<ResponsiveContainer width="99%" height="100%">
								<BarChart data={data?.marketEntryTicketAggregateByDOWReport.points}>
									<XAxis dataKey="day" />
									<YAxis dataKey={yDataKey} tickFormatter={formatYTick} />
									<Tooltip isAnimationActive={false} formatter={tooltipFormatter} />

									<Bar {...defaultBarProps} dataKey={yDataKey} fill={categoricalColorSchemes.tableau10[0]} />
								</BarChart>
							</ResponsiveContainer>
						</Else>
					</Conditional>
				</ChartContainer>
			</Section.Body>
		</Section>
	);
}

function AggregateByVehicleType({ startDate, endDate, metric }: TicketChartProps) {
	const { loading, data, error } = useMarketEntryTicketAggregateByVehicleTypeReportQuery({
		variables: { startDate, endDate },
	});

	const yDataKey = getYDataKeyFromMetric(metric);
	const tooltipFormatter = React.useMemo(() => {
		if (metric === "revenue") {
			return (value: number, name: MarketEntryTicketVehicleType) => [
				formatMoneyTick(value),
				MarketEntryTicketFormat.Fields.vehicleType(name),
			];
		}
		return (value: number, name: MarketEntryTicketVehicleType) => [
			formatIntTick(value),
			MarketEntryTicketFormat.Fields.vehicleType(name),
		];
	}, [metric]);

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Heading level={2} noMargin>
					Vehicle Types
				</Heading>
			</Section.Header>

			<Section.Body>
				<ChartContainer>
					<Conditional>
						<If condition={loading}>Loading...</If>
						<If condition={!!error}>There was a problem loading this report.</If>
						<Else>
							<ResponsiveContainer width="99%" height="100%">
								<PieChart>
									<Tooltip isAnimationActive={false} formatter={tooltipFormatter} />

									<Pie
										{...defaultPieProps}
										data={data?.marketEntryTicketAggregateByVehicleTypeReport.points}
										nameKey="vehicleType"
										dataKey={yDataKey}
										label={getPieLabel((name: string) =>
											MarketEntryTicketFormat.Fields.vehicleType(name as MarketEntryTicketVehicleType),
										)}
									>
										{!!data &&
											data.marketEntryTicketAggregateByVehicleTypeReport.points.map((e, i) => (
												<Cell key={e.vehicleType} fill={getColor(categoricalColorSchemes.tableau10, i)} />
											))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</Else>
					</Conditional>
				</ChartContainer>
			</Section.Body>
		</Section>
	);
}

function AggregateByMarketEntrance({ startDate, endDate, metric }: TicketChartProps) {
	const { loading, data, error } = useMarketEntryTicketAggregateByMarketEntranceReportQuery({
		variables: { startDate, endDate },
	});

	const yDataKey = getYDataKeyFromMetric(metric);
	const formatYTick = metric === "revenue" ? formatMoneyTick : formatIntTick;

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Heading level={2} noMargin>
					Market Entrances
				</Heading>
			</Section.Header>

			<Section.Body>
				<ChartContainer>
					<Conditional>
						<If condition={loading}>Loading...</If>
						<If condition={!!error}>There was a problem loading this report.</If>
						<Else>
							<ResponsiveContainer width="99%" height="100%">
								<PieChart>
									<Tooltip isAnimationActive={false} formatter={formatYTick} />

									<Pie
										{...defaultPieProps}
										data={data?.marketEntryTicketAggregateByMarketEntranceReport.points}
										nameKey="marketEntranceName"
										dataKey={yDataKey}
										label={getPieLabel()}
									>
										{!!data &&
											data.marketEntryTicketAggregateByMarketEntranceReport.points.map((e, i) => (
												<Cell key={e.marketEntranceID} fill={getColor(categoricalColorSchemes.tableau10, i)} />
											))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</Else>
					</Conditional>
				</ChartContainer>
			</Section.Body>
		</Section>
	);
}

type ChartContainerProps = Partial<ChildrenProps>;

function ChartContainer({ children }: ChartContainerProps) {
	return <div style={{ width: "100%", height: "60vh" }}>{children}</div>;
}
