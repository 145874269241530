import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const userHubPageInfo = newPageInfo({
	title: "Users",
	description: "Pages related to the User model.",
	exact: false,
	to: hubsPageInfo.to + "/users",
	path: hubsPageInfo.path + "/users",
	parent: hubsPageInfo,
});
