import { newPageInfo } from "@hex-insights/router";
import { marketEntryTicketHubPageInfo } from "../pageinfo";

export const marketEntryTicketHubIndexPageInfo = newPageInfo({
	name: "Market Entry Tickets Index",
	title: "",
	description: "Index of Market Entry Tickets.",
	exact: true,
	to: marketEntryTicketHubPageInfo.to,
	path: marketEntryTicketHubPageInfo.path,
	parent: marketEntryTicketHubPageInfo,
});
