import React from "react";
import { Authentication } from "@hex-insights/app-modules";
import { NavigationPrompt, Route } from "@hex-insights/router";
import { Footer, Header, PageRouteSwitch } from "./Components";
import { TrustedDeviceController } from "./Components/TrustedDeviceController";
import { AuthenticationContext } from "./Contexts";
import { AccountDisabledPage } from "./Pages/Authentication/AccountDisabled";
import { LoginPage } from "./Pages/Authentication/Login";
import { loginPageInfo } from "./Pages/Authentication/Login/pageinfo";
import { LogoutPage } from "./Pages/Authentication/Logout";
import { logoutPageInfo } from "./Pages/Authentication/Logout/pageinfo";
import { RecoverPage } from "./Pages/Authentication/Recover";
import { recoverPageInfo } from "./Pages/Authentication/Recover/pageinfo";
import { SetPasswordPage } from "./Pages/Authentication/SetPassword";
import { setPasswordPageInfo } from "./Pages/Authentication/SetPassword/pageinfo";
import { SplashPage } from "./Pages/Authentication/Splash";
import { HomePage } from "./Pages/Home";
import { homePageInfo } from "./Pages/Home/pageinfo";
import { HubsPage } from "./Pages/Hubs";
import { hubsPageInfo } from "./Pages/Hubs/pageinfo";
import { ReportsPage } from "./Pages/Reports";
import { reportsPageInfo } from "./Pages/Reports/pageinfo";

export function App() {
	const { status } = React.useContext(AuthenticationContext);

	switch (status) {
		case Authentication.AuthenticationStatus.Loading:
			return <SplashPage />;
		case Authentication.AuthenticationStatus.Disabled:
			return <AppDisabled />;
		case Authentication.AuthenticationStatus.Unauthenticated:
			return <AppUnauthenticated />;
		default:
			return <AppAuthenticated />;
	}
}

function AppAuthenticated() {
	return (
		<React.Fragment>
			<NavigationPrompt
				message={(targetLocation) =>
					targetLocation.pathname === logoutPageInfo.to ? "Are you sure you want to log out?" : true
				}
			/>

			<Header />

			<PageRouteSwitch>
				<Route {...homePageInfo.routeProps}>
					<HomePage />
				</Route>
				<Route {...reportsPageInfo.routeProps}>
					<ReportsPage />
				</Route>
				<Route {...hubsPageInfo.routeProps}>
					<HubsPage />
				</Route>
				<Route {...logoutPageInfo.routeProps}>
					<LogoutPage />
				</Route>
			</PageRouteSwitch>

			<TrustedDeviceController />

			<Footer />
		</React.Fragment>
	);
}

function AppUnauthenticated() {
	return (
		<PageRouteSwitch>
			<Route {...recoverPageInfo.routeProps}>
				<RecoverPage />
			</Route>
			<Route {...setPasswordPageInfo.routeProps}>
				<SetPasswordPage />
			</Route>
			<Route {...logoutPageInfo.routeProps}>
				<LogoutPage />
			</Route>
			<Route {...loginPageInfo.routeProps}>
				<LoginPage />
			</Route>
		</PageRouteSwitch>
	);
}

function AppDisabled() {
	return (
		<PageRouteSwitch>
			<Route {...logoutPageInfo}>
				<LogoutPage />
			</Route>
			<Route>
				<AccountDisabledPage />
			</Route>
		</PageRouteSwitch>
	);
}
