import React from "react";
import { Navigation, Runners } from "@hex-insights/app-modules";
import {
	booleanTypeFilter,
	Button,
	Column,
	Conditional,
	Drawer,
	Else,
	If,
	jsxJoin,
	makeClassName,
	Row,
	scrollToTop,
	useBodyClassName,
	useLocalStorage,
	useMaxStackIndex,
	useMediaQueries,
	useToggle,
} from "@hex-insights/core";
import { InternalLink, NavLink, useActivePageInfo, useLocation } from "@hex-insights/router";
import { logoutPageInfo } from "../../Pages/Authentication/Logout/pageinfo";
import { homePageInfo } from "../../Pages/Home/pageinfo";
import { hubsPageInfo } from "../../Pages/Hubs/pageinfo";
import { reportsPageInfo } from "../../Pages/Reports/pageinfo";
import { GlobalSearchBar } from "../GlobalSearch";
import styles from "./styles.module.css";

export function Header() {
	return (
		<React.Fragment>
			<Navigation.SkipToContentLink />
			<Runners.Header Bar={HeaderBar} Drawer={HeaderDrawerController} SideBar={HeaderSideBar} className="no-print" />
		</React.Fragment>
	);
}

const bodyWithNavigationToolbarClassName = "body--with-navigation-toolbar";
const navigationToolbarIsOpenLocalStorageKey = "ui.header.navigationToolbar.isOpen";

function HeaderBar({ toggleDrawer }: Runners.HeaderBarProps) {
	const { getItem, setItem } = useLocalStorage();
	const { isOn: isNavigationToolbarOpen, toggle: toggleIsNavigationToolbarOpen } = useToggle(() =>
		getItem(navigationToolbarIsOpenLocalStorageKey, booleanTypeFilter()),
	);
	useBodyClassName(bodyWithNavigationToolbarClassName, isNavigationToolbarOpen);
	React.useEffect(() => {
		setItem(navigationToolbarIsOpenLocalStorageKey, isNavigationToolbarOpen);
	}, [setItem, isNavigationToolbarOpen]);

	return (
		<Row justify="space-between" align="center" className={styles["header__bar"]}>
			<Button variant="secondary" onClick={toggleDrawer} className={styles["header__bar__drawer-button"]}>
				&#8801;
			</Button>

			<Row justify="spaced-start" align="center" className={styles["header__bar__title-and-search"]}>
				<InternalLink to={homePageInfo.to} className={styles["header__bar__site-title"]}>
					Roadium Portal
				</InternalLink>

				<GlobalSearchBar expands withKeyboardShortcut className={styles["header__bar__global-search"]} />

				<div className={styles["header__bar__navigation-toolbar-button-container"]}>
					<Button
						variant="secondary"
						size="small"
						onClick={toggleIsNavigationToolbarOpen}
						title="Toggle navigation toolbar"
					>
						&#8943;
					</Button>
				</div>
			</Row>

			<Row justify="spaced-end" align="center">
				<nav className={styles["header__bar__nav"]}>
					<Row justify="spaced-start">
						<NavLink to={homePageInfo.to} exact={homePageInfo.exact}>
							Home
						</NavLink>
						<NavLink to={reportsPageInfo.to} exact={reportsPageInfo.exact}>
							Reports
						</NavLink>
						<NavLink to={hubsPageInfo.to} exact={hubsPageInfo.exact}>
							Hubs
						</NavLink>
						<NavLink to={logoutPageInfo.to} exact={logoutPageInfo.exact}>
							Logout
						</NavLink>
					</Row>
				</nav>
			</Row>

			<If condition={isNavigationToolbarOpen}>
				<HeaderNavigationToolbar />
			</If>
		</Row>
	);
}

export function HeaderNavigationToolbar() {
	const { activePagesList } = useActivePageInfo();
	const { pathname } = useLocation();
	const { breadCrumbPages, showBackLink, backTo } = Navigation.getBreadCrumbPages(activePagesList, pathname);

	return (
		<Row
			justify="spaced-start"
			horizontalSpacing="0.5rem"
			align="center"
			className={styles["header__bar__navigation-toolbar"]}
		>
			<Conditional>
				<If condition={showBackLink}>
					<InternalLink to={backTo} className={styles["header__bar__navigation-toolbar__back-link"]}>
						&#8249;
					</InternalLink>
				</If>
				<Else>
					<span
						className={makeClassName(
							styles["header__bar__navigation-toolbar__back-link"],
							styles["header__bar__navigation-toolbar__back-link--placeholder"],
						)}
					></span>
				</Else>
			</Conditional>

			<Row
				justify="spaced-start"
				horizontalSpacing="0.5rem"
				align="center"
				overflow="scroll"
				className={styles["header__bar__navigation-toolbar__bread-crumbs"]}
			>
				{jsxJoin(
					breadCrumbPages.map((e) => (
						<InternalLink key={e.url} to={e.url} onClick={e.url === pathname ? scrollToTop : undefined}>
							{e.stringTitle}
						</InternalLink>
					)),
					"/",
				)}
			</Row>
		</Row>
	);
}

function HeaderDrawerController({ isOpen, onClose }: Runners.HeaderDrawerProps) {
	if (!isOpen) {
		return null;
	}
	return <HeaderDrawer onClose={onClose} />;
}

const desktopMediaQuery = "(min-width: 800px)";
const mediaQueries = [desktopMediaQuery];

function HeaderDrawer({ onClose }: Pick<Runners.HeaderDrawerProps, "onClose">) {
	const media = useMediaQueries(mediaQueries);
	const zIndex = useMaxStackIndex();

	React.useEffect(() => {
		if (media[desktopMediaQuery]) {
			onClose();
		}
	}, [onClose, media]);

	const onNavigateClick = React.useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			if (event.ctrlKey || event.metaKey) {
				return;
			}
			onClose();
		},
		[onClose],
	);

	const onSelectResult = React.useCallback(
		(_: any, event: React.MouseEvent | React.KeyboardEvent) => {
			onNavigateClick(event);
		},
		[onNavigateClick],
	);

	return (
		<Drawer location="left" isModal onClose={onClose} zIndex={zIndex} className={styles["header__drawer"]}>
			<Drawer.Body className={styles["header__drawer__body"]}>
				<Column justify="spaced-start">
					<div style={{ marginBottom: "2rem" }}>
						<GlobalSearchBar onSelectResult={onSelectResult} className={styles["header__drawer__global-search"]} />
					</div>

					<nav className={styles["header__drawer__nav"]}>
						<Column justify="spaced-start">
							<NavLink to={homePageInfo.to} exact={homePageInfo.exact} onClick={onNavigateClick}>
								Home
							</NavLink>
							<NavLink to={reportsPageInfo.to} exact={reportsPageInfo.exact} onClick={onNavigateClick}>
								Reports
							</NavLink>
							<NavLink to={hubsPageInfo.to} exact={hubsPageInfo.exact} onClick={onNavigateClick}>
								Hubs
							</NavLink>
							<NavLink to={logoutPageInfo.to} exact={logoutPageInfo.exact} onClick={onNavigateClick}>
								Logout
							</NavLink>
						</Column>
					</nav>
				</Column>
			</Drawer.Body>
		</Drawer>
	);
}

function HeaderSideBar({ isOpen }: Runners.HeaderSideBarProps) {
	if (!isOpen) {
		return null;
	}

	return (
		<Drawer
			location="right"
			zIndex={1}
			className={styles["header__side-bar"]}
			wrapperClassName={styles["header__side-bar__wrapper"]}
		>
			<Drawer.Body> </Drawer.Body>
		</Drawer>
	);
}
