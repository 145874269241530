import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { HolidayForm, useHolidayDetailQuery } from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { holidayHubDetailPageInfo } from "./pageinfo";

export function HolidayHubDetailPage() {
	const { holidayID } = holidayHubDetailPageInfo.useParams();

	const { loading, data, error } = useHolidayDetailQuery({ variables: { id: holidayID } });
	const { syncID, synchronize } = useSync();

	const holiday = data?.holiday;

	useActivePageRegistration(holidayHubDetailPageInfo, !!holiday ? holidayHubDetailPageInfo.title(holiday) : "");

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading holiday</If>
				<Else>
					{!!holiday && (
						<React.Fragment>
							<Heading level={1}>{holiday.name}</Heading>

							<HolidayForm.ControlledDetail key={syncID} holiday={holiday} onSuccess={synchronize} />
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
