import { newPageInfo } from "@hex-insights/router";
import { reportsPageInfo } from "../pageinfo";

export const dailyRegisterReportPageInfo = newPageInfo({
	title: "Daily Register Report",
	description: "Daily register report page.",
	exact: true,
	to: "/reports/daily-register",
	path: "/reports/daily-register",
	parent: reportsPageInfo,
});
