import { User } from "@hex-insights/roadium.shared";
import { newPageInfo } from "@hex-insights/router";
import { userHubDetailPageInfo } from "../pageinfo";

export const userHubDetailMainPageInfo = newPageInfo({
	name: "User Detail Main page",
	title: "",
	description: "Detail page for an user.",
	exact: true,
	to: (id: User["id"]) => userHubDetailPageInfo.to(id),
	path: userHubDetailPageInfo.path,
	parent: userHubDetailPageInfo,
	paramsType: userHubDetailPageInfo.paramsType,
});
