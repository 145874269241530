import { newPageInfo } from "@hex-insights/router";
import { marketEntryTicketHubPageInfo } from "../pageinfo";

export const marketEntryTicketHubCreatePageInfo = newPageInfo({
	name: "New Market Entry Ticket",
	title: "New",
	description: "Create a new Market Entry Ticket record.",
	exact: true,
	to: marketEntryTicketHubPageInfo.to + "/new",
	path: marketEntryTicketHubPageInfo.path + "/new",
	parent: marketEntryTicketHubPageInfo,
});
