import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { MarketEntranceForm, useMarketEntranceDetailQuery } from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { marketEntranceHubDetailPageInfo } from "./pageinfo";

export function MarketEntranceHubDetailPage() {
	const { marketEntranceID } = marketEntranceHubDetailPageInfo.useParams();

	const { loading, data, error } = useMarketEntranceDetailQuery({ variables: { id: marketEntranceID } });
	const { syncID, synchronize } = useSync();

	const marketEntrance = data?.marketEntrance;

	useActivePageRegistration(
		marketEntranceHubDetailPageInfo,
		!!marketEntrance ? marketEntranceHubDetailPageInfo.title(marketEntrance) : "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading marketEntrance</If>
				<Else>
					{!!marketEntrance && (
						<React.Fragment>
							<Heading level={1}>{marketEntrance.name}</Heading>

							<MarketEntranceForm.ControlledDetail
								key={syncID}
								marketEntrance={marketEntrance}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
