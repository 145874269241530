import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { MarketEntryTicketForm, useMarketEntryTicketDetailQuery } from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { marketEntryTicketHubDetailPageInfo } from "./pageinfo";

export function MarketEntryTicketHubDetailPage() {
	const { marketEntryTicketID } = marketEntryTicketHubDetailPageInfo.useParams();

	const { loading, data, error } = useMarketEntryTicketDetailQuery({ variables: { id: marketEntryTicketID } });
	const { syncID, synchronize } = useSync();

	const marketEntryTicket = data?.marketEntryTicket;

	useActivePageRegistration(
		marketEntryTicketHubDetailPageInfo,
		!!marketEntryTicket ? marketEntryTicketHubDetailPageInfo.title(marketEntryTicket) : "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading marketEntryTicket</If>
				<Else>
					{!!marketEntryTicket && (
						<React.Fragment>
							<Heading level={1}>{marketEntryTicket.id}</Heading>

							<MarketEntryTicketForm.ControlledDetail
								key={syncID}
								marketEntryTicket={marketEntryTicket}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
