import React from "react";
import { ApolloError } from "@apollo/client";
import { Column, Conditional, Else, formatDateTime, Heading, If, Paragraph, Row } from "@hex-insights/core";
import {
	toAuthenticationQRCodeDataString,
	User,
	UserDetailQuery,
	useUserAuthenticationTokenQuery,
} from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import { BasicTable } from "@hex-insights/tables";
import { Main } from "../../../../../Components";
import { userHubDetailAuthQRCodePageInfo } from "./pageinfo";

export type UserHubDetailAuthQRCodePageProps = {
	id: User["id"];
	isLoading: boolean;
	user?: UserDetailQuery["user"];
	error?: ApolloError;
};

export function UserHubDetailAuthQRCodePage({ id, user, isLoading, error }: UserHubDetailAuthQRCodePageProps) {
	useActivePageRegistration(userHubDetailAuthQRCodePageInfo);

	const {
		loading: isLoadingToken,
		data: tokenData,
		error: tokenError,
	} = useUserAuthenticationTokenQuery({ variables: { userID: id }, fetchPolicy: "network-only" });
	const qrData = React.useMemo(
		() => toAuthenticationQRCodeDataString(tokenData?.userAuthenticationToken ?? ""),
		[tokenData],
	);

	const qrSize = 350;

	return (
		<Main>
			<Conditional>
				<If condition={isLoading}>Loading...</If>
				<If condition={error !== undefined}>Error loading user</If>
				<Else>
					{!!user && (
						<React.Fragment>
							<Heading level={1} style={{ textAlign: "center", marginBottom: "6rem" }}>
								Roadium Ticket App Login
							</Heading>

							<Column justify="spaced-start" align="center" verticalSpacing="4rem">
								<Conditional>
									<If condition={isLoadingToken}>Loading QR Code...</If>
									<If condition={tokenError !== undefined}>
										Error loading QR code{!!tokenError && `: ${tokenError.message}`}
									</If>
									<Else>
										<img
											src={`https://barcode.hexinsights.dev/qr?value=${qrData}&size=${qrSize}`}
											width={qrSize}
											height={qrSize}
										/>
									</Else>
								</Conditional>

								<BasicTable>
									<If condition={!!user.marketEntranceStation}>
										<BasicTable.Row>
											<BasicTable.Cell>Entrance:</BasicTable.Cell>
											<BasicTable.Cell>
												{user.marketEntranceStation?.marketEntrance.name}: {user.marketEntranceStation?.name}
											</BasicTable.Cell>
										</BasicTable.Row>
									</If>
									<BasicTable.Row>
										<BasicTable.Cell>Email:</BasicTable.Cell>
										<BasicTable.Cell>{user.email}</BasicTable.Cell>
									</BasicTable.Row>
									<BasicTable.Row>
										<BasicTable.Cell>Password:</BasicTable.Cell>
										<BasicTable.Cell>
											<span className="no-print">[Write password here]</span>
										</BasicTable.Cell>
									</BasicTable.Row>
								</BasicTable>

								<Row justify="center">
									<Paragraph style={{ fontSize: "0.9rem" }}>Generated: {formatDateTime(new Date())}</Paragraph>
								</Row>
							</Column>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
