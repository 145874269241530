import React from "react";
import { Button, Conditional, Else, Heading, If, jsxJoin, List, Modal, useToggle } from "@hex-insights/core";
import {
	PermissionGroupLink,
	PermissionLink,
	usePermissionsOwnedByUserLazyQuery,
	User,
} from "@hex-insights/roadium.shared";

export type PermissionsOwnedByUserProps = {
	id: User["id"];
};

export function PermissionsOwnedByUser({ id }: PermissionsOwnedByUserProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);
	const [load, { loading, data, error }] = usePermissionsOwnedByUserLazyQuery({ variables: { id } });

	React.useEffect(() => {
		if (isOpen) {
			load();
		}
	}, [isOpen, load]);

	const permissions = data?.permissionConnection;

	return (
		<React.Fragment>
			<Button variant="tertiary" onClick={toggleIsOpen}>
				View Permissions Owned by This User
			</Button>

			<Modal.If condition={isOpen}>
				<Modal onClose={toggleIsOpen}>
					<Modal.Header>
						<Heading level={2} noMargin>
							Owned Permissions
						</Heading>
					</Modal.Header>

					<Modal.Body>
						<Conditional>
							<If condition={loading}>Loading...</If>
							<If condition={error !== undefined}>Error loading users</If>
							<Else>
								{!!permissions && (
									<List>
										{permissions.edges.map((e) => (
											<List.Item key={e.node.id} style={{ marginBottom: "0.75rem" }}>
												<PermissionLink instance={e.node} />
												<div style={{ margin: "0.25rem 0 0 0.25rem", fontSize: "0.9rem" }}>
													Through:{" "}
													{jsxJoin(
														e.node.permissionGroups.map((pg) => <PermissionGroupLink key={pg.id} instance={pg} />),
														", ",
													)}
												</div>
											</List.Item>
										))}
									</List>
								)}
							</Else>
						</Conditional>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}
