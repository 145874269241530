import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import { PermissionGroupForm, usePermissionGroupDetailQuery } from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { permissionGroupHubDetailPageInfo } from "./pageinfo";

export function PermissionGroupHubDetailPage() {
	const { permissionGroupID } = permissionGroupHubDetailPageInfo.useParams();

	const { loading, data, error } = usePermissionGroupDetailQuery({ variables: { id: permissionGroupID } });
	const { syncID, synchronize } = useSync();

	const permissionGroup = data?.permissionGroup;

	useActivePageRegistration(
		permissionGroupHubDetailPageInfo,
		!!permissionGroup ? permissionGroupHubDetailPageInfo.title(permissionGroup) : "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading permissionGroup</If>
				<Else>
					{!!permissionGroup && (
						<React.Fragment>
							<Heading level={1}>{permissionGroup.name}</Heading>

							<PermissionGroupForm.ControlledDetail
								key={syncID}
								permissionGroup={permissionGroup}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
