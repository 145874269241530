import React from "react";
import { useApolloClient } from "@apollo/client";
import { GlobalSearch } from "@hex-insights/app-modules";
import { compareNumbersAsc, isString } from "@hex-insights/core";
import {
	GlobalSearchDocument,
	GlobalSearchQuery,
	GlobalSearchQueryVariables,
	SearchResultFilterInput,
	titleCaseModelNames,
} from "@hex-insights/roadium.shared";
import { PageInfo } from "@hex-insights/router";
import { allPages } from "../../all-pages";
import { hubDetailPages } from "../../hub-detail-pages";
import { allModelNamesFilterInput } from "./models";

const searchablePages = getSearchablePages();

export type SearchQueryArgs = {
	filters: SearchResultFilterInput;
};

const baseSearchConfig: Omit<GlobalSearch.SearchConfig<SearchQueryArgs>, "queryRecords"> = {
	searchablePages,
	hubDetailPages,
	formatModelName: (pascalCaseModelName: string) => titleCaseModelNames[pascalCaseModelName] ?? pascalCaseModelName,
};

export const defaultQueryArgs: SearchQueryArgs = { filters: allModelNamesFilterInput };

export function useSearch(search: string, categories: GlobalSearch.SearchCategories, queryArgs: SearchQueryArgs) {
	const client = useApolloClient();

	const searchConfig: GlobalSearch.SearchConfig<SearchQueryArgs> = React.useMemo(() => {
		return {
			...baseSearchConfig,
			queryRecords: async (search: string, queryArgs: SearchQueryArgs) => {
				const { data } = await client.query<GlobalSearchQuery, GlobalSearchQueryVariables>({
					query: GlobalSearchDocument,
					variables: { filters: [{ ...queryArgs.filters, search }] },
				});
				return data.searchResultConnection.edges.map((e) => e.node);
			},
		};
	}, [client]);

	return GlobalSearch.useSearch<SearchQueryArgs>(searchConfig, search, categories, queryArgs);
}

function getSearchablePages() {
	const searchablePages = allPages.filter((e) => !e.noSearch && e.isLeaf && isString(e.to)) as PageInfo<
		any,
		string,
		any
	>[];

	const compare = compareNumbersAsc();
	searchablePages.sort((a, b) => compare(countPathParts(a.to), countPathParts(b.to)));

	return searchablePages;
}

function countPathParts(path: string) {
	return (path.match(/\//g) ?? []).length;
}
