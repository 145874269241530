import { newPageInfo } from "@hex-insights/router";
import { marketEntranceStationHubPageInfo } from "../pageinfo";

export const marketEntranceStationHubIndexPageInfo = newPageInfo({
	name: "Market Entrance Stations Index",
	title: "",
	description: "Index of Market Entrance Stations.",
	exact: true,
	to: marketEntranceStationHubPageInfo.to,
	path: marketEntranceStationHubPageInfo.path,
	parent: marketEntranceStationHubPageInfo,
});
