import { newPageInfo } from "@hex-insights/router";
import { marketEntranceHubPageInfo } from "../pageinfo";

export const marketEntranceHubCreatePageInfo = newPageInfo({
	name: "New Market Entrance",
	title: "New",
	description: "Create a new Market Entrance record.",
	exact: true,
	to: marketEntranceHubPageInfo.to + "/new",
	path: marketEntranceHubPageInfo.path + "/new",
	parent: marketEntranceHubPageInfo,
});
