// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import { holidayHubDetailPageInfo } from "./Pages/Hubs/Holiday/DetailPage/pageinfo";
import { marketEntranceHubDetailPageInfo } from "./Pages/Hubs/MarketEntrance/DetailPage/pageinfo";
import { marketEntrancePricingStructureHubDetailPageInfo } from "./Pages/Hubs/MarketEntrancePricingStructure/DetailPage/pageinfo";
import { marketEntranceStationHubDetailPageInfo } from "./Pages/Hubs/MarketEntranceStation/DetailPage/pageinfo";
import { marketEntryTicketHubDetailPageInfo } from "./Pages/Hubs/MarketEntryTicket/DetailPage/pageinfo";
import { permissionHubDetailPageInfo } from "./Pages/Hubs/Permission/DetailPage/pageinfo";
import { permissionGroupHubDetailPageInfo } from "./Pages/Hubs/PermissionGroup/DetailPage/pageinfo";
import { userHubDetailPageInfo } from "./Pages/Hubs/User/DetailPage/pageinfo";

export const hubDetailPages = {
	Holiday: holidayHubDetailPageInfo,
	MarketEntrance: marketEntranceHubDetailPageInfo,
	MarketEntrancePricingStructure: marketEntrancePricingStructureHubDetailPageInfo,
	MarketEntranceStation: marketEntranceStationHubDetailPageInfo,
	MarketEntryTicket: marketEntryTicketHubDetailPageInfo,
	Permission: permissionHubDetailPageInfo,
	PermissionGroup: permissionGroupHubDetailPageInfo,
	User: userHubDetailPageInfo,
};
