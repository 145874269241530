import { Heading, Paragraph } from "@hex-insights/core";
import { RouteSwitch, RouteSwitchProps } from "@hex-insights/router";
import { Main } from "../Main";

export type PageRouteSwitchProps = RouteSwitchProps;

export function PageRouteSwitch({ fallback = <DefaultNotFoundPage />, ...props }: PageRouteSwitchProps) {
	return <RouteSwitch fallback={fallback} {...props} />;
}

function DefaultNotFoundPage() {
	return (
		<Main>
			<Heading level={1}>404: Not Found</Heading>

			<Paragraph>The page you're looking for has moved or does not exist.</Paragraph>
		</Main>
	);
}
