import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { MarketEntrancePricingStructureHubCreatePage } from "./CreatePage";
import { marketEntrancePricingStructureHubCreatePageInfo } from "./CreatePage/pageinfo";
import { MarketEntrancePricingStructureHubDetailPage } from "./DetailPage";
import { marketEntrancePricingStructureHubDetailPageInfo } from "./DetailPage/pageinfo";
import { MarketEntrancePricingStructureHubIndexPage } from "./IndexPage";
import { marketEntrancePricingStructureHubIndexPageInfo } from "./IndexPage/pageinfo";
import { marketEntrancePricingStructureHubPageInfo } from "./pageinfo";

export function MarketEntrancePricingStructureHub() {
	useActivePageRegistration(marketEntrancePricingStructureHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...marketEntrancePricingStructureHubIndexPageInfo.routeProps}>
				<MarketEntrancePricingStructureHubIndexPage />
			</Route>
			<Route {...marketEntrancePricingStructureHubCreatePageInfo.routeProps}>
				<MarketEntrancePricingStructureHubCreatePage />
			</Route>
			<Route {...marketEntrancePricingStructureHubDetailPageInfo.routeProps}>
				<MarketEntrancePricingStructureHubDetailPage key={marketEntrancePricingStructureHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
