import React from "react";
import { Button, Column, Heading, Modal, ModalProps, useToggle } from "@hex-insights/core";
import {
	Form,
	FormSubmitFunction,
	PasswordField,
	submissionFailure,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	useFormState,
} from "@hex-insights/forms";
import { serverRequest, User } from "@hex-insights/roadium.shared";
import { AuthenticationContext } from "../../../../../Contexts";

export type PasswordUpdaterProps = {
	id: User["id"];
};

export function PasswordUpdater({ id }: PasswordUpdaterProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);
	const { user: authenticatedUser } = React.useContext(AuthenticationContext);

	if (authenticatedUser === null || id !== authenticatedUser.id) {
		return null;
	}

	return (
		<React.Fragment>
			<Button variant="secondary" onClick={toggleIsOpen}>
				Update Password
			</Button>

			<Modal.If condition={isOpen}>
				<UpdatePasswordModal onClose={toggleIsOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type UpdatePasswordFormValues = {
	password: string;
	newPassword: string;
};

const initialUpdatePasswordFormValues: UpdatePasswordFormValues = {
	password: "",
	newPassword: "",
};

type UpdatePasswordModalProps = Pick<ModalProps, "ifRef" | "onClose">;

function UpdatePasswordModal({ ifRef, onClose }: UpdatePasswordModalProps) {
	const formState = useFormState({
		initialFormValues: initialUpdatePasswordFormValues,
	});

	const onSubmit = React.useCallback<FormSubmitFunction<UpdatePasswordFormValues>>(async ({ formValues }) => {
		const response = await serverRequest("/auth/change-password", {
			method: "POST",
			body: JSON.stringify(formValues),
		});
		if (!response.ok) {
			const responseBody = await response.json();
			if (responseBody.error === "Invalid credentials.") {
				return submissionFailure({ password: { _: ["Incorrect current password."] } });
			}
			return submissionFailure({ _: ["Something went wrong."] });
		}
		return submissionSuccess();
	}, []);

	React.useEffect(() => {
		if (onClose && SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onClose();
		}
	}, [formState.submissionStatus, onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Update Password
				</Heading>
			</Modal.Header>

			<Modal.Body>
				<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noChangesDialog noLocalStorage>
					<Column justify="spaced-start">
						<PasswordField formState={formState} name="password" label="Current Password" autoFocus />

						<PasswordField
							formState={formState}
							name="newPassword"
							withStrengthEstimator
							allowVisibility
							minLength={8}
						/>

						<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
							Update Password
						</SubmitButton>
					</Column>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
