// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import { loginPageInfo } from "./Pages/Authentication/Login/pageinfo";
import { logoutPageInfo } from "./Pages/Authentication/Logout/pageinfo";
import { mfaLoginPageInfo } from "./Pages/Authentication/MFALogin/pageinfo";
import { recoverPageInfo } from "./Pages/Authentication/Recover/pageinfo";
import { setPasswordPageInfo } from "./Pages/Authentication/SetPassword/pageinfo";
import { homePageInfo } from "./Pages/Home/pageinfo";
import { hubsDetailRedirectPageInfo } from "./Pages/Hubs/DetailRedirectPage/pageinfo";
import { holidayHubCreatePageInfo } from "./Pages/Hubs/Holiday/CreatePage/pageinfo";
import { holidayHubDetailPageInfo } from "./Pages/Hubs/Holiday/DetailPage/pageinfo";
import { holidayHubIndexPageInfo } from "./Pages/Hubs/Holiday/IndexPage/pageinfo";
import { holidayHubPageInfo } from "./Pages/Hubs/Holiday/pageinfo";
import { hubsIndexPageInfo } from "./Pages/Hubs/IndexPage/pageinfo";
import { marketEntranceHubCreatePageInfo } from "./Pages/Hubs/MarketEntrance/CreatePage/pageinfo";
import { marketEntranceHubDetailPageInfo } from "./Pages/Hubs/MarketEntrance/DetailPage/pageinfo";
import { marketEntranceHubIndexPageInfo } from "./Pages/Hubs/MarketEntrance/IndexPage/pageinfo";
import { marketEntranceHubPageInfo } from "./Pages/Hubs/MarketEntrance/pageinfo";
import { marketEntrancePricingStructureHubCreatePageInfo } from "./Pages/Hubs/MarketEntrancePricingStructure/CreatePage/pageinfo";
import { marketEntrancePricingStructureHubDetailPageInfo } from "./Pages/Hubs/MarketEntrancePricingStructure/DetailPage/pageinfo";
import { marketEntrancePricingStructureHubIndexPageInfo } from "./Pages/Hubs/MarketEntrancePricingStructure/IndexPage/pageinfo";
import { marketEntrancePricingStructureHubPageInfo } from "./Pages/Hubs/MarketEntrancePricingStructure/pageinfo";
import { marketEntranceStationHubCreatePageInfo } from "./Pages/Hubs/MarketEntranceStation/CreatePage/pageinfo";
import { marketEntranceStationHubDetailPageInfo } from "./Pages/Hubs/MarketEntranceStation/DetailPage/pageinfo";
import { marketEntranceStationHubIndexPageInfo } from "./Pages/Hubs/MarketEntranceStation/IndexPage/pageinfo";
import { marketEntranceStationHubPageInfo } from "./Pages/Hubs/MarketEntranceStation/pageinfo";
import { marketEntryTicketHubCreatePageInfo } from "./Pages/Hubs/MarketEntryTicket/CreatePage/pageinfo";
import { marketEntryTicketHubDetailPageInfo } from "./Pages/Hubs/MarketEntryTicket/DetailPage/pageinfo";
import { marketEntryTicketHubIndexPageInfo } from "./Pages/Hubs/MarketEntryTicket/IndexPage/pageinfo";
import { marketEntryTicketHubPageInfo } from "./Pages/Hubs/MarketEntryTicket/pageinfo";
import { hubsPageInfo } from "./Pages/Hubs/pageinfo";
import { permissionHubDetailPageInfo } from "./Pages/Hubs/Permission/DetailPage/pageinfo";
import { permissionHubIndexPageInfo } from "./Pages/Hubs/Permission/IndexPage/pageinfo";
import { permissionHubPageInfo } from "./Pages/Hubs/Permission/pageinfo";
import { permissionGroupHubCreatePageInfo } from "./Pages/Hubs/PermissionGroup/CreatePage/pageinfo";
import { permissionGroupHubDetailPageInfo } from "./Pages/Hubs/PermissionGroup/DetailPage/pageinfo";
import { permissionGroupHubIndexPageInfo } from "./Pages/Hubs/PermissionGroup/IndexPage/pageinfo";
import { permissionGroupHubPageInfo } from "./Pages/Hubs/PermissionGroup/pageinfo";
import { userHubCreatePageInfo } from "./Pages/Hubs/User/CreatePage/pageinfo";
import { userHubDetailAuthQRCodePageInfo } from "./Pages/Hubs/User/DetailPage/AuthQRCode/pageinfo";
import { userHubDetailMainPageInfo } from "./Pages/Hubs/User/DetailPage/Main/pageinfo";
import { userHubDetailPageInfo } from "./Pages/Hubs/User/DetailPage/pageinfo";
import { userHubIndexPageInfo } from "./Pages/Hubs/User/IndexPage/pageinfo";
import { userHubPageInfo } from "./Pages/Hubs/User/pageinfo";
import { dailyRegisterReportPageInfo } from "./Pages/Reports/DailyRegisterReport/pageinfo";
import { reportsIndexPageInfo } from "./Pages/Reports/IndexPage/pageinfo";
import { reportsPageInfo } from "./Pages/Reports/pageinfo";
import { ticketsTimelineReportPageInfo } from "./Pages/Reports/TicketsTimelineReport/pageinfo";
import { rootPageInfo } from "./root.pageinfo";

export const allPages = [
	loginPageInfo,
	logoutPageInfo,
	mfaLoginPageInfo,
	recoverPageInfo,
	setPasswordPageInfo,
	homePageInfo,
	hubsDetailRedirectPageInfo,
	holidayHubCreatePageInfo,
	holidayHubDetailPageInfo,
	holidayHubIndexPageInfo,
	holidayHubPageInfo,
	hubsIndexPageInfo,
	marketEntranceHubCreatePageInfo,
	marketEntranceHubDetailPageInfo,
	marketEntranceHubIndexPageInfo,
	marketEntranceHubPageInfo,
	marketEntrancePricingStructureHubCreatePageInfo,
	marketEntrancePricingStructureHubDetailPageInfo,
	marketEntrancePricingStructureHubIndexPageInfo,
	marketEntrancePricingStructureHubPageInfo,
	marketEntranceStationHubCreatePageInfo,
	marketEntranceStationHubDetailPageInfo,
	marketEntranceStationHubIndexPageInfo,
	marketEntranceStationHubPageInfo,
	marketEntryTicketHubCreatePageInfo,
	marketEntryTicketHubDetailPageInfo,
	marketEntryTicketHubIndexPageInfo,
	marketEntryTicketHubPageInfo,
	permissionHubDetailPageInfo,
	permissionHubIndexPageInfo,
	permissionHubPageInfo,
	permissionGroupHubCreatePageInfo,
	permissionGroupHubDetailPageInfo,
	permissionGroupHubIndexPageInfo,
	permissionGroupHubPageInfo,
	userHubCreatePageInfo,
	userHubDetailAuthQRCodePageInfo,
	userHubDetailMainPageInfo,
	userHubDetailPageInfo,
	userHubIndexPageInfo,
	userHubPageInfo,
	hubsPageInfo,
	dailyRegisterReportPageInfo,
	reportsIndexPageInfo,
	ticketsTimelineReportPageInfo,
	reportsPageInfo,
	rootPageInfo,
];
