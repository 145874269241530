import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { MarketEntryTicketHubCreatePage } from "./CreatePage";
import { marketEntryTicketHubCreatePageInfo } from "./CreatePage/pageinfo";
import { MarketEntryTicketHubDetailPage } from "./DetailPage";
import { marketEntryTicketHubDetailPageInfo } from "./DetailPage/pageinfo";
import { MarketEntryTicketHubIndexPage } from "./IndexPage";
import { marketEntryTicketHubIndexPageInfo } from "./IndexPage/pageinfo";
import { marketEntryTicketHubPageInfo } from "./pageinfo";

export function MarketEntryTicketHub() {
	useActivePageRegistration(marketEntryTicketHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...marketEntryTicketHubIndexPageInfo.routeProps}>
				<MarketEntryTicketHubIndexPage />
			</Route>
			<Route {...marketEntryTicketHubCreatePageInfo.routeProps}>
				<MarketEntryTicketHubCreatePage />
			</Route>
			<Route {...marketEntryTicketHubDetailPageInfo.routeProps}>
				<MarketEntryTicketHubDetailPage key={marketEntryTicketHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
