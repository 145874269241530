import { abbreviateNumber, formatDateTime, formatFloat, formatInt, stringToLocalDate } from "@hex-insights/core";
import { MarketEntryTicketFormat } from "@hex-insights/roadium.shared";

export type TicketReportMetric = "guests" | "tickets" | "revenue";

export const ticketReportMetricOptions = [
	{
		value: "guests",
	},
	{
		value: "tickets",
	},
	{
		value: "revenue",
	},
];

export function formatDateLabel(value: string) {
	return formatDateTime(stringToLocalDate(value, "day"), "ddd, MMM Do, YYYY");
}

export function formatDateTick(value: string) {
	const format = new Date(value).getFullYear() === new Date().getFullYear() ? "MM/DD" : "MM/DD/YY";
	return formatDateTime(stringToLocalDate(value, "day"), format);
}

export function formatTimeOnlyLabel(value: string) {
	return formatDateTime(value, "h:mm A");
}

export function formatTimeOnlyTick(value: string) {
	return formatDateTime(value, "h:mm");
}

export function tooltipIntFormatter(value: number, name: string) {
	return [formatIntTick(value), formatMetricName(name)];
}

export function tooltipFloatFormatter(value: number, name: string) {
	return [formatFloatTick(value), formatMetricName(name)];
}

export function tooltipMoneyFormatter(value: number, name: string) {
	return [formatMoneyTick(value), formatMetricName(name)];
}

export function formatMetricName(name: string) {
	switch (name) {
		case "numGuests":
			return "Guests";
		case "numTickets":
			return "Tickets";
		case "revenue":
			return "Revenue";
		default:
			return name;
	}
}

export function getYDataKeyFromMetric(metric: TicketReportMetric) {
	switch (metric) {
		case "guests":
			return "numGuests";
		case "tickets":
			return "numTickets";
		case "revenue":
			return "revenue";
	}
}

export function formatIntTick(value: number) {
	return formatInt(value);
}

export function formatAbbreviatedIntTick(value: number) {
	return abbreviateNumber(value);
}

export function formatFloatTick(value: number) {
	return formatFloat(value);
}

export function formatAbbreviatedFloatTick(value: number) {
	return abbreviateNumber(value);
}

export function formatMoneyTick(value: number) {
	return MarketEntryTicketFormat.Fields.amountPaid(value);
}

export function formatAbbreviatedMoneyTick(value: number) {
	return `$${abbreviateNumber(value / 100)}`;
}

// include in defaultPieProps
export function getPieLabel(formatName: (name: string) => string = defaultFormatName) {
	return (entry: any) => {
		return formatName(entry.name);
	};
}

export function defaultFormatName(name: string) {
	return name;
}

export function getColor(colorSet: string[], index: number) {
	return colorSet[index % colorSet.length];
}
