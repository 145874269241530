import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import {
	MarketEntranceLink,
	MarketEntrancePricingStructureFilterForm,
	MarketEntrancePricingStructureFilterFormState,
	MarketEntrancePricingStructureFilterFormUtils,
	MarketEntrancePricingStructureFormat,
	MarketEntrancePricingStructureIndexQuery,
	MarketEntrancePricingStructureOrderField,
	MarketEntrancePricingStructureOrderForm,
	MarketEntrancePricingStructureOrderFormState,
	MarketEntrancePricingStructureOrderFormValues,
	MarketEntrancePricingStructurePaginationForm,
	useMarketEntrancePricingStructureIndexQuery,
} from "@hex-insights/roadium.shared";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import { Main } from "../../../../Components";
import { marketEntrancePricingStructureHubCreatePageInfo } from "../CreatePage/pageinfo";
import { marketEntrancePricingStructureHubDetailPageInfo } from "../DetailPage/pageinfo";
import { marketEntrancePricingStructureHubIndexPageInfo } from "./pageinfo";

const columns: (keyof MarketEntrancePricingStructureIndexQuery["marketEntrancePricingStructureConnection"]["edges"][0]["node"])[] =
	[
		"id",
		"marketEntrance",
		"effectiveDate",
		"hasHolidayPricing",
		"carRegularWeekdayPrice",
		"carRegularWeekendPrice",
		"carRegularHolidayPrice",
		"carSeniorWeekdayPrice",
		"carSeniorWeekendPrice",
		"carSeniorHolidayPrice",
		"bikeRegularWeekdayPrice",
		"bikeRegularWeekendPrice",
		"bikeRegularHolidayPrice",
		"bikeSeniorWeekdayPrice",
		"bikeSeniorWeekendPrice",
		"bikeSeniorHolidayPrice",
		"passengerChildWeekdayPrice",
		"passengerChildWeekendPrice",
		"passengerChildHolidayPrice",
		"passengerRegularWeekdayPrice",
		"passengerRegularWeekendPrice",
		"passengerRegularHolidayPrice",
		"passengerSeniorWeekdayPrice",
		"passengerSeniorWeekendPrice",
		"passengerSeniorHolidayPrice",
		"footChildWeekdayPrice",
		"footChildWeekendPrice",
		"footChildHolidayPrice",
		"footRegularWeekdayPrice",
		"footRegularWeekendPrice",
		"footRegularHolidayPrice",
		"footSeniorWeekdayPrice",
		"footSeniorWeekendPrice",
		"footSeniorHolidayPrice",
	];

function orderFieldToColumn(field: MarketEntrancePricingStructureOrderField | null) {
	if (field === null) {
		return "id";
	}
	return MarketEntrancePricingStructureOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return MarketEntrancePricingStructureOrderFormValues.modelFieldToOrderField[column];
}

export function MarketEntrancePricingStructureHubIndexPage() {
	useActivePageRegistration(marketEntrancePricingStructureHubIndexPageInfo);

	const filterFormState = MarketEntrancePricingStructureFilterFormState.useFilterFormStateWithQueryStateSync();
	const orderFormState = MarketEntrancePricingStructureOrderFormState.useOrderFormStateWithQueryStateSync();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		MarketEntrancePricingStructureFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useMarketEntrancePricingStructureIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		MarketEntrancePricingStructureFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = MarketEntrancePricingStructureFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) -
			(data?.marketEntrancePricingStructureConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Market Entrance Pricing Structures
				</Heading>

				<InternalLink to={marketEntrancePricingStructureHubCreatePageInfo.to}>
					New Market Entrance Pricing Structure
				</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				withVerticalSpacing
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<MarketEntrancePricingStructureFilterForm.FilterController
					formState={filterFormState}
					numActiveFilters={numActiveFilters}
				/>
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<MarketEntrancePricingStructureOrderForm.OrderController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns}
					minColumnWidth="10rem"
					scrollSyncID={paginationFormState.formValues.cursor}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="marketEntrance" noSort>
								Market Entrance
							</Table.Heading>
							<Table.Heading column="effectiveDate">Effective Date</Table.Heading>
							<Table.Heading column="hasHolidayPricing" noSort>
								Holiday Pricing
							</Table.Heading>
							<Table.Heading column="carRegularWeekdayPrice" noSort>
								Car Regular Weekday Price
							</Table.Heading>
							<Table.Heading column="carRegularWeekendPrice" noSort>
								Car Regular Weekend Price
							</Table.Heading>
							<Table.Heading column="carRegularHolidayPrice" noSort>
								Car Regular Holiday Price
							</Table.Heading>
							<Table.Heading column="carSeniorWeekdayPrice" noSort>
								Car Senior Weekday Price
							</Table.Heading>
							<Table.Heading column="carSeniorWeekendPrice" noSort>
								Car Senior Weekend Price
							</Table.Heading>
							<Table.Heading column="carSeniorHolidayPrice" noSort>
								Car Senior Holiday Price
							</Table.Heading>
							<Table.Heading column="bikeRegularWeekdayPrice" noSort>
								Bike Regular Weekday Price
							</Table.Heading>
							<Table.Heading column="bikeRegularWeekendPrice" noSort>
								Bike Regular Weekend Price
							</Table.Heading>
							<Table.Heading column="bikeRegularHolidayPrice" noSort>
								Bike Regular Holiday Price
							</Table.Heading>
							<Table.Heading column="bikeSeniorWeekdayPrice" noSort>
								Bike Senior Weekday Price
							</Table.Heading>
							<Table.Heading column="bikeSeniorWeekendPrice" noSort>
								Bike Senior Weekend Price
							</Table.Heading>
							<Table.Heading column="bikeSeniorHolidayPrice" noSort>
								Bike Senior Holiday Price
							</Table.Heading>
							<Table.Heading column="passengerChildWeekdayPrice" noSort>
								Passenger Child Weekday Price
							</Table.Heading>
							<Table.Heading column="passengerChildWeekendPrice" noSort>
								Passenger Child Weekend Price
							</Table.Heading>
							<Table.Heading column="passengerChildHolidayPrice" noSort>
								Passenger Child Holiday Price
							</Table.Heading>
							<Table.Heading column="passengerRegularWeekdayPrice" noSort>
								Passenger Regular Weekday Price
							</Table.Heading>
							<Table.Heading column="passengerRegularWeekendPrice" noSort>
								Passenger Regular Weekend Price
							</Table.Heading>
							<Table.Heading column="passengerRegularHolidayPrice" noSort>
								Passenger Regular Holiday Price
							</Table.Heading>
							<Table.Heading column="passengerSeniorWeekdayPrice" noSort>
								Passenger Senior Weekday Price
							</Table.Heading>
							<Table.Heading column="passengerSeniorWeekendPrice" noSort>
								Passenger Senior Weekend Price
							</Table.Heading>
							<Table.Heading column="passengerSeniorHolidayPrice" noSort>
								Passenger Senior Holiday Price
							</Table.Heading>
							<Table.Heading column="footChildWeekdayPrice" noSort>
								Foot Child Weekday Price
							</Table.Heading>
							<Table.Heading column="footChildWeekendPrice" noSort>
								Foot Child Weekend Price
							</Table.Heading>
							<Table.Heading column="footChildHolidayPrice" noSort>
								Foot Child Holiday Price
							</Table.Heading>
							<Table.Heading column="footRegularWeekdayPrice" noSort>
								Foot Regular Weekday Price
							</Table.Heading>
							<Table.Heading column="footRegularWeekendPrice" noSort>
								Foot Regular Weekend Price
							</Table.Heading>
							<Table.Heading column="footRegularHolidayPrice" noSort>
								Foot Regular Holiday Price
							</Table.Heading>
							<Table.Heading column="footSeniorWeekdayPrice" noSort>
								Foot Senior Weekday Price
							</Table.Heading>
							<Table.Heading column="footSeniorWeekendPrice" noSort>
								Foot Senior Weekend Price
							</Table.Heading>
							<Table.Heading column="footSeniorHolidayPrice" noSort>
								Foot Senior Holiday Price
							</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column="id" colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.marketEntrancePricingStructureConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine>
											<InternalLink to={marketEntrancePricingStructureHubDetailPageInfo.to(e.node.id)}>
												{e.node.id}
											</InternalLink>
										</Table.Cell>
										<Table.Cell column="marketEntrance" oneLine>
											<MarketEntranceLink instance={e.node.marketEntrance} />
										</Table.Cell>
										<Table.Cell column="effectiveDate" oneLine>
											{MarketEntrancePricingStructureFormat.Fields.effectiveDateShort(e.node.effectiveDate)}
										</Table.Cell>
										<Table.Cell column="hasHolidayPricing" oneLine>
											{MarketEntrancePricingStructureFormat.Fields.hasHolidayPricing(e.node.hasHolidayPricing)}
										</Table.Cell>
										<Table.Cell column="carRegularWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.carRegularWeekdayPrice(
												e.node.carRegularWeekdayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="carRegularWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.carRegularWeekendPrice(
												e.node.carRegularWeekendPrice,
											)}
										</Table.Cell>
										<Table.Cell column="carRegularHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.carRegularHolidayPrice(
												e.node.carRegularHolidayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="carSeniorWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.carSeniorWeekdayPrice(e.node.carSeniorWeekdayPrice)}
										</Table.Cell>
										<Table.Cell column="carSeniorWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.carSeniorWeekendPrice(e.node.carSeniorWeekendPrice)}
										</Table.Cell>
										<Table.Cell column="carSeniorHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.carSeniorHolidayPrice(e.node.carSeniorHolidayPrice)}
										</Table.Cell>
										<Table.Cell column="bikeRegularWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.bikeRegularWeekdayPrice(
												e.node.bikeRegularWeekdayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="bikeRegularWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.bikeRegularWeekendPrice(
												e.node.bikeRegularWeekendPrice,
											)}
										</Table.Cell>
										<Table.Cell column="bikeRegularHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.bikeRegularHolidayPrice(
												e.node.bikeRegularHolidayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="bikeSeniorWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.bikeSeniorWeekdayPrice(
												e.node.bikeSeniorWeekdayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="bikeSeniorWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.bikeSeniorWeekendPrice(
												e.node.bikeSeniorWeekendPrice,
											)}
										</Table.Cell>
										<Table.Cell column="bikeSeniorHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.bikeSeniorHolidayPrice(
												e.node.bikeSeniorHolidayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerChildWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerChildWeekdayPrice(
												e.node.passengerChildWeekdayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerChildWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerChildWeekendPrice(
												e.node.passengerChildWeekendPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerChildHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerChildHolidayPrice(
												e.node.passengerChildHolidayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerRegularWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerRegularWeekdayPrice(
												e.node.passengerRegularWeekdayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerRegularWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerRegularWeekendPrice(
												e.node.passengerRegularWeekendPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerRegularHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerRegularHolidayPrice(
												e.node.passengerRegularHolidayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerSeniorWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerSeniorWeekdayPrice(
												e.node.passengerSeniorWeekdayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerSeniorWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerSeniorWeekendPrice(
												e.node.passengerSeniorWeekendPrice,
											)}
										</Table.Cell>
										<Table.Cell column="passengerSeniorHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.passengerSeniorHolidayPrice(
												e.node.passengerSeniorHolidayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="footChildWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footChildWeekdayPrice(e.node.footChildWeekdayPrice)}
										</Table.Cell>
										<Table.Cell column="footChildWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footChildWeekendPrice(e.node.footChildWeekendPrice)}
										</Table.Cell>
										<Table.Cell column="footChildHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footChildHolidayPrice(e.node.footChildHolidayPrice)}
										</Table.Cell>
										<Table.Cell column="footRegularWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footRegularWeekdayPrice(
												e.node.footRegularWeekdayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="footRegularWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footRegularWeekendPrice(
												e.node.footRegularWeekendPrice,
											)}
										</Table.Cell>
										<Table.Cell column="footRegularHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footRegularHolidayPrice(
												e.node.footRegularHolidayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="footSeniorWeekdayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footSeniorWeekdayPrice(
												e.node.footSeniorWeekdayPrice,
											)}
										</Table.Cell>
										<Table.Cell column="footSeniorWeekendPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footSeniorWeekendPrice(
												e.node.footSeniorWeekendPrice,
											)}
										</Table.Cell>
										<Table.Cell column="footSeniorHolidayPrice" oneLine numeric>
											{MarketEntrancePricingStructureFormat.Fields.footSeniorHolidayPrice(
												e.node.footSeniorHolidayPrice,
											)}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<MarketEntrancePricingStructurePaginationForm.PaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.marketEntrancePricingStructureConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
