import { newPageInfo } from "@hex-insights/router";
import { reportsPageInfo } from "../pageinfo";

export const reportsIndexPageInfo = newPageInfo({
	name: "Reports Index",
	title: "",
	description: "Directory for reports.",
	exact: true,
	to: "/reports",
	path: "/reports",
	parent: reportsPageInfo,
});
