import React from "react";
import { Heading } from "@hex-insights/core";
import { UserForm } from "@hex-insights/roadium.shared";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { userHubDetailPageInfo } from "../DetailPage/pageinfo";
import { userHubCreatePageInfo } from "./pageinfo";

export function UserHubCreatePage() {
	useActivePageRegistration(userHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<UserForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(userHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading level={1}>New User</Heading>

			<UserForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
