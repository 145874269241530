import React from "react";
import { Heading } from "@hex-insights/core";
import { PermissionGroupForm } from "@hex-insights/roadium.shared";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { permissionGroupHubDetailPageInfo } from "../DetailPage/pageinfo";
import { permissionGroupHubCreatePageInfo } from "./pageinfo";

export function PermissionGroupHubCreatePage() {
	useActivePageRegistration(permissionGroupHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<PermissionGroupForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(permissionGroupHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading level={1}>New Permission Group</Heading>

			<PermissionGroupForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
