import * as Log from "@hex-insights/log";
import { appName, appVersion, instanceID, projectName } from "./settings";

export function configureLogging() {
	Log.setFields({
		project: projectName,
		domain: "frontend",
		app: appName,
		version: appVersion,
		instanceID,
	});

	const levelName = process.env.REACT_APP_LOG_LEVEL ?? "";
	if (levelName !== "") {
		const level = Log.Level.fromName(levelName);
		if (level === null) {
			Log.warn(`Log level '${levelName}' is not supported, using default`);
		} else {
			Log.setLevel(level);
		}
	}
}
