import React from "react";
import { Heading } from "@hex-insights/core";
import { MarketEntranceForm } from "@hex-insights/roadium.shared";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { marketEntranceHubDetailPageInfo } from "../DetailPage/pageinfo";
import { marketEntranceHubCreatePageInfo } from "./pageinfo";

export function MarketEntranceHubCreatePage() {
	useActivePageRegistration(marketEntranceHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<MarketEntranceForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(marketEntranceHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading level={1}>New Market Entrance</Heading>

			<MarketEntranceForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
