// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import React from "react";
import { BooleanField, FormState } from "@hex-insights/forms";
import { SearchResultFilterInput } from "@hex-insights/roadium.shared";

export type ModelNameFiltersFormValues = {
	includeHoliday: boolean;
	includeMarketEntrance: boolean;
	includeMarketEntranceStation: boolean;
	includePermissionGroup: boolean;
	includeUser: boolean;
};

export const initialModelNameFiltersFormValues: ModelNameFiltersFormValues = {
	includeHoliday: true,
	includeMarketEntrance: true,
	includeMarketEntranceStation: true,
	includePermissionGroup: true,
	includeUser: true,
};

export function checkAllModels(formState: FormState<ModelNameFiltersFormValues>) {
	formState.formSetFunctions.includeHoliday(true);
	formState.formSetFunctions.includeMarketEntrance(true);
	formState.formSetFunctions.includeMarketEntranceStation(true);
	formState.formSetFunctions.includePermissionGroup(true);
	formState.formSetFunctions.includeUser(true);
}

export function uncheckAllModels(formState: FormState<ModelNameFiltersFormValues>) {
	formState.formSetFunctions.includeHoliday(false);
	formState.formSetFunctions.includeMarketEntrance(false);
	formState.formSetFunctions.includeMarketEntranceStation(false);
	formState.formSetFunctions.includePermissionGroup(false);
	formState.formSetFunctions.includeUser(false);
}

type ModelNameFilterInputsProps = {
	formState: FormState<ModelNameFiltersFormValues>;
};

export function ModelNameFilterInputs({ formState }: ModelNameFilterInputsProps) {
	return (
		<React.Fragment>
			<BooleanField formState={formState} name="includeHoliday" label="Holidays" noToolbar />
			<BooleanField formState={formState} name="includeMarketEntrance" label="Market Entrances" noToolbar />
			<BooleanField
				formState={formState}
				name="includeMarketEntranceStation"
				label="Market Entrance Stations"
				noToolbar
			/>
			<BooleanField formState={formState} name="includePermissionGroup" label="Permission Groups" noToolbar />
			<BooleanField formState={formState} name="includeUser" label="Users" noToolbar />
		</React.Fragment>
	);
}

export const allModelNamesFilterInput = {
	modelNameIn: ["Holiday", "MarketEntrance", "MarketEntranceStation", "PermissionGroup", "User"],
};

export function convertModelNameFilterFormValuesToFilterInput(formValues: ModelNameFiltersFormValues) {
	const modelNames: SearchResultFilterInput["modelNameIn"] = [];

	if (formValues.includeHoliday) {
		modelNames.push("Holiday");
	}
	if (formValues.includeMarketEntrance) {
		modelNames.push("MarketEntrance");
	}
	if (formValues.includeMarketEntranceStation) {
		modelNames.push("MarketEntranceStation");
	}
	if (formValues.includePermissionGroup) {
		modelNames.push("PermissionGroup");
	}
	if (formValues.includeUser) {
		modelNames.push("User");
	}

	if (modelNames.length === 0) {
		return allModelNamesFilterInput.modelNameIn;
	}

	return modelNames;
}
