import { MarketEntryTicket } from "@hex-insights/roadium.shared";
import { newPageInfo, newRouteParamsType } from "@hex-insights/router";
import { marketEntryTicketHubPageInfo } from "../pageinfo";

type MarketEntryTicketForRouteTitle = Pick<MarketEntryTicket, "id">;

export const marketEntryTicketHubDetailPageInfo = newPageInfo({
	name: "Market Entry Ticket Detail",
	title: (e: MarketEntryTicketForRouteTitle) => e.id,
	description: "Detail page for an marketEntryTicket.",
	exact: false,
	isLeaf: true,
	to: (id: MarketEntryTicket["id"]) => marketEntryTicketHubPageInfo.to + `/${id}`,
	path: marketEntryTicketHubPageInfo.path + "/:marketEntryTicketID(\\d+)",
	parent: marketEntryTicketHubPageInfo,
	paramsType: newRouteParamsType(marketEntryTicketHubPageInfo.paramsType, "marketEntryTicketID"),
});
