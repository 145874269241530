import { formatDateTime, Row, Section } from "@hex-insights/core";
import { MarketDay, PriceCalculation, useCurrentMarketDay } from "@hex-insights/roadium.shared";
import { InternalLink } from "@hex-insights/router";
import { marketEntranceHubIndexPageInfo } from "../../Hubs/MarketEntrance/IndexPage/pageinfo";
import { userHubIndexPageInfo } from "../../Hubs/User/IndexPage/pageinfo";
import { dailyRegisterReportPageInfo } from "../../Reports/DailyRegisterReport/pageinfo";

export function Welcome() {
	const { marketDay, marketDayNoHoliday } = useCurrentMarketDay();

	const currentTime = new Date();
	const hour = currentTime.getHours();
	let sectionOfDay = "";
	if (hour <= 2 || hour >= 18) {
		sectionOfDay = "Evening";
	} else if (hour < 12) {
		sectionOfDay = "Morning";
	} else {
		sectionOfDay = "Afternoon";
	}

	return (
		<Section className="tile">
			<Section.Header className="tile__header" style={{ borderBottom: "none" }}>
				<span style={{ fontSize: "1.5rem", width: "fit-content", borderBottom: "4px solid var(--roadium-red)" }}>
					Good {sectionOfDay},
				</span>
			</Section.Header>

			<Section.Body>
				<Row justify="spaced-start" align="flex-end">
					<span>
						Today is {formatDateTime(currentTime, "dddd, MMMM Do")}. {displayMarketDay(marketDay, marketDayNoHoliday)}{" "}
						pricing is in effect at the market.
					</span>
					<InternalLink to={dailyRegisterReportPageInfo.to}>Run Daily Register Report</InternalLink>
					<InternalLink to={marketEntranceHubIndexPageInfo.to}>Manage Market Entrances</InternalLink>
					<InternalLink to={userHubIndexPageInfo.to}>Print App QR Codes</InternalLink>
				</Row>
			</Section.Body>
		</Section>
	);
}

function displayMarketDay(marketDay: MarketDay, marketDayNoHoliday: MarketDay) {
	if (marketDay === MarketDay.Holiday) {
		return `${PriceCalculation.Format.formatMarketDay(marketDayNoHoliday)}/${PriceCalculation.Format.formatMarketDay(
			marketDay,
		)}`;
	}
	return PriceCalculation.Format.formatMarketDay(marketDay);
}
