import { newPageInfo } from "@hex-insights/router";
import { marketEntranceStationHubPageInfo } from "../pageinfo";

export const marketEntranceStationHubCreatePageInfo = newPageInfo({
	name: "New Market Entrance Station",
	title: "New",
	description: "Create a new Market Entrance Station record.",
	exact: true,
	to: marketEntranceStationHubPageInfo.to + "/new",
	path: marketEntranceStationHubPageInfo.path + "/new",
	parent: marketEntranceStationHubPageInfo,
});
