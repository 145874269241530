import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { MarketEntranceStationHubCreatePage } from "./CreatePage";
import { marketEntranceStationHubCreatePageInfo } from "./CreatePage/pageinfo";
import { MarketEntranceStationHubDetailPage } from "./DetailPage";
import { marketEntranceStationHubDetailPageInfo } from "./DetailPage/pageinfo";
import { MarketEntranceStationHubIndexPage } from "./IndexPage";
import { marketEntranceStationHubIndexPageInfo } from "./IndexPage/pageinfo";
import { marketEntranceStationHubPageInfo } from "./pageinfo";

export function MarketEntranceStationHub() {
	useActivePageRegistration(marketEntranceStationHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...marketEntranceStationHubIndexPageInfo.routeProps}>
				<MarketEntranceStationHubIndexPage />
			</Route>
			<Route {...marketEntranceStationHubCreatePageInfo.routeProps}>
				<MarketEntranceStationHubCreatePage />
			</Route>
			<Route {...marketEntranceStationHubDetailPageInfo.routeProps}>
				<MarketEntranceStationHubDetailPage key={marketEntranceStationHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
