import React from "react";
import {
	Button,
	Column,
	Conditional,
	formatDateTime,
	formatInt,
	formatMoney,
	Heading,
	If,
	Paragraph,
	preventDefault,
	Row,
	Section,
	stringToLocalDate,
	toCSV,
	toLocalDateString,
} from "@hex-insights/core";
import {
	DateTimeField,
	FormState,
	FormType,
	RadioField,
	useFormState,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import {
	MarketEntranceLink,
	MarketEntranceStationLink,
	MarketEntryTicketAggregateByMarketEntranceStationDetailReportQuery,
	MarketEntryTicketAggregateByMarketEntranceStationReportQuery,
	MarketEntryTicketAggregateByUserReportQuery,
	useMarketEntryTicketAggregateByMarketEntranceStationDetailReportQuery,
	useMarketEntryTicketAggregateByMarketEntranceStationReportQuery,
	useMarketEntryTicketAggregateByUserReportQuery,
	UserLink,
} from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import { Table, tableComparisonFunctions, TableComparisonFunctions, useTableSorting } from "@hex-insights/tables";
import { PrintPageBreak } from "../../../Components";
import { dailyRegisterReportPageInfo } from "./pageinfo";

type DailyRegisterReportFormValues = {
	date: string | null;
	groupBy: "marketEntranceStation" | "user";
};

function getInitialDailyRegisterReportFormValues(): DailyRegisterReportFormValues {
	return {
		date: toLocalDateString(new Date()),
		groupBy: "marketEntranceStation",
	};
}

export function DailyRegisterReportPage() {
	useActivePageRegistration(dailyRegisterReportPageInfo);

	const formState = useFormState({
		initialFormValues: getInitialDailyRegisterReportFormValues(),
		formType: FormType.Standing,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});

	return (
		<div>
			<div className="no-print">
				<Heading level={2}>Daily Register Report</Heading>

				<Column justify="spaced-start">
					<DailyRegisterReportForm formState={formState} />

					<Section className="tile">
						<Section.Header className="tile__header">
							<Heading level={3} noMargin>
								Overview
							</Heading>
						</Section.Header>

						<Section.Body>
							<Conditional>
								<If condition={formState.formValues.groupBy === "marketEntranceStation"}>
									<DailyRegisterReportByMarketEntranceStation date={formState.formValues.date} />
								</If>
								<If condition={formState.formValues.groupBy === "user"}>
									<DailyRegisterReportByUser date={formState.formValues.date} />
								</If>
							</Conditional>
						</Section.Body>
					</Section>

					<Section className="tile">
						<Section.Header className="tile__header">
							<Heading level={3} noMargin>
								Detailed Breakdown
							</Heading>
						</Section.Header>

						<Section.Body>
							<div style={{ marginBottom: "1rem" }}>
								<Button variant="primary" onClick={window.print}>
									Print
								</Button>
							</div>

							<DailyRegisterReportByMarketEntranceStationDetail date={formState.formValues.date} />
						</Section.Body>
					</Section>
				</Column>
			</div>

			<div className="print-only">
				<DailyRegisterReportByMarketEntranceStationDetailPrint date={formState.formValues.date} />
			</div>
		</div>
	);
}

const groupByOptions = [
	{
		value: "marketEntranceStation",
	},
	{
		value: "user",
	},
];

type DailyRegisterReportFormProps = {
	formState: FormState<DailyRegisterReportFormValues>;
};

function DailyRegisterReportForm({ formState }: DailyRegisterReportFormProps) {
	return (
		<form onSubmit={preventDefault} style={{ marginBottom: "1rem" }}>
			<Row justify="spaced-start">
				<DateTimeField formState={formState} name="date" noClear />
				<RadioField formState={formState} name="groupBy" options={groupByOptions} noClear />
			</Row>
		</form>
	);
}

const valueComparisonFunctions: TableComparisonFunctions<DataPoint> = {
	numTickets: tableComparisonFunctions.numbers,
	numGuests: tableComparisonFunctions.numbers,
	revenue: tableComparisonFunctions.numbers,
};

const marketEntranceStationComparisonFunctions: TableComparisonFunctions<MarketEntranceStationPoint> = {
	marketEntranceID: tableComparisonFunctions.strings,
	marketEntranceName: tableComparisonFunctions.strings,
	marketEntranceStationID: tableComparisonFunctions.strings,
	marketEntranceStationName: tableComparisonFunctions.strings,
	...valueComparisonFunctions,
};

type MarketEntranceStationPoint =
	MarketEntryTicketAggregateByMarketEntranceStationReportQuery["marketEntryTicketAggregateByMarketEntranceStationReport"]["points"][0];

const emptyMarketEntrancePoints: MarketEntranceStationPoint[] = [];

const marketEntranceStationColumns: (keyof MarketEntranceStationPoint)[] = [
	"marketEntranceName",
	"marketEntranceStationName",
	"numTickets",
	"numGuests",
	"revenue",
];
const marketEntranceStationDownloadColumns: (keyof MarketEntranceStationPoint)[] = [
	"marketEntranceID",
	"marketEntranceName",
	"marketEntranceStationID",
	"marketEntranceStationName",
	"numTickets",
	"numGuests",
	"revenue",
];

type DailyRegisterReportByMarketEntranceStationProps = {
	date: string | null;
};

function DailyRegisterReportByMarketEntranceStation({ date }: DailyRegisterReportByMarketEntranceStationProps) {
	const currentDate = toLocalDateString(new Date());
	const { loading, data, error } = useMarketEntryTicketAggregateByMarketEntranceStationReportQuery({
		variables: { startDate: date ?? currentDate, endDate: date ?? currentDate },
	});

	const points = data?.marketEntryTicketAggregateByMarketEntranceStationReport.points ?? emptyMarketEntrancePoints;
	const {
		sortedData: sortedPoints,
		sortColumn,
		sortIsAsc,
		updateSort,
	} = useTableSorting<MarketEntranceStationPoint>(points, marketEntranceStationComparisonFunctions);
	const totals = React.useMemo(() => calculateTotals(points), [points]);

	const file = React.useMemo(
		() =>
			toCSV(
				`daily-register-report-by-market-entrance-station${date ? "-" + date.split("T")[0] : ""}`,
				points,
				marketEntranceStationDownloadColumns,
			),
		[date, points],
	);
	const fileURL = URL.createObjectURL(file.data);

	if (loading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>There was a problem loading this report.</div>;
	}

	if (!points) {
		return <div>No data to display.</div>;
	}

	return (
		<Table.Container className="hub__index-page__table__container">
			<Table
				columns={marketEntranceStationColumns}
				sortColumn={sortColumn}
				sortIsAsc={sortIsAsc}
				updateSort={updateSort}
			>
				<Table.Header className="hub__index-page__table__header">
					<Table.Row>
						<Table.Heading column="marketEntranceName">Market Entrance</Table.Heading>
						<Table.Heading column="marketEntranceStationName">Market Entrance Station</Table.Heading>
						<Table.Heading column="numTickets">Tickets</Table.Heading>
						<Table.Heading column="numGuests">Guests</Table.Heading>
						<Table.Heading column="revenue">Revenue</Table.Heading>
					</Table.Row>
				</Table.Header>

				<Table.Body className="hub__index-page__table__body">
					{sortedPoints.map((e) => (
						<Table.Row key={e.marketEntranceStationID}>
							<Table.Cell column="marketEntranceName" oneLine>
								<MarketEntranceLink instance={{ id: e.marketEntranceID, name: e.marketEntranceName }} />
							</Table.Cell>
							<Table.Cell column="marketEntranceStationName" oneLine>
								<MarketEntranceStationLink
									instance={{
										id: e.marketEntranceStationID,
									}}
								>
									{e.marketEntranceStationName}
								</MarketEntranceStationLink>
							</Table.Cell>
							<Table.Cell column="numTickets" oneLine numeric>
								{formatInt(e.numTickets)}
							</Table.Cell>
							<Table.Cell column="numGuests" oneLine numeric>
								{formatInt(e.numGuests)}
							</Table.Cell>
							<Table.Cell column="revenue" oneLine numeric>
								{formatMoney(e.revenue, { areCents: true })}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>

				<Table.Footer>
					<Table.Row>
						<Table.Cell column="marketEntrance" oneLine>
							Total
						</Table.Cell>
						<Table.Cell column="numTickets" oneLine numeric>
							{formatInt(totals.totalTickets)}
						</Table.Cell>
						<Table.Cell column="numGuests" oneLine numeric>
							{formatInt(totals.totalGuests)}
						</Table.Cell>
						<Table.Cell column="revenue" oneLine numeric>
							{formatMoney(totals.totalRevenue, { areCents: true })}
						</Table.Cell>
					</Table.Row>
				</Table.Footer>
			</Table>

			<Table.Toolbar>
				<a href={fileURL} download={file.name}>
					Download
				</a>
			</Table.Toolbar>
		</Table.Container>
	);
}

type UserPoint = MarketEntryTicketAggregateByUserReportQuery["marketEntryTicketAggregateByUserReport"]["points"][0];

const emptyUserPoints: UserPoint[] = [];

const userComparisonFunctions: TableComparisonFunctions<UserPoint> = {
	userID: tableComparisonFunctions.strings,
	userEmail: tableComparisonFunctions.strings,
	...valueComparisonFunctions,
};

const userColumns: (keyof UserPoint)[] = ["userEmail", "numTickets", "numGuests", "revenue"];
const userDownloadColumns: (keyof UserPoint)[] = ["userID", "userEmail", "numTickets", "numGuests", "revenue"];

type DailyRegisterReportByUserProps = {
	date: string | null;
};

function DailyRegisterReportByUser({ date }: DailyRegisterReportByUserProps) {
	const currentDate = toLocalDateString(new Date());
	const { loading, data, error } = useMarketEntryTicketAggregateByUserReportQuery({
		variables: { startDate: date ?? currentDate, endDate: date ?? currentDate },
	});

	const points = data?.marketEntryTicketAggregateByUserReport.points ?? emptyUserPoints;
	const {
		sortedData: sortedPoints,
		sortColumn,
		sortIsAsc,
		updateSort,
	} = useTableSorting<UserPoint>(points, userComparisonFunctions);
	const totals = React.useMemo(() => calculateTotals(points), [points]);

	const file = React.useMemo(
		() => toCSV(`daily-register-report-by-user${date ? "-" + date.split("T")[0] : ""}`, points, userDownloadColumns),
		[date, points],
	);
	const fileURL = URL.createObjectURL(file.data);

	if (loading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>There was a problem loading this report.</div>;
	}

	if (!points) {
		return <div>No data to display.</div>;
	}

	return (
		<Table.Container className="hub__index-page__table__container">
			<Table columns={userColumns} sortColumn={sortColumn} sortIsAsc={sortIsAsc} updateSort={updateSort}>
				<Table.Header className="hub__index-page__table__header">
					<Table.Row>
						<Table.Heading column="userEmail">User</Table.Heading>
						<Table.Heading column="numTickets">Tickets</Table.Heading>
						<Table.Heading column="numGuests">Guests</Table.Heading>
						<Table.Heading column="revenue">Revenue</Table.Heading>
					</Table.Row>
				</Table.Header>

				<Table.Body className="hub__index-page__table__body">
					{sortedPoints.map((e) => (
						<Table.Row key={e.userID}>
							<Table.Cell column="userEmail" oneLine>
								<UserLink instance={{ id: e.userID, email: e.userEmail }} />
							</Table.Cell>
							<Table.Cell column="numTickets" oneLine numeric>
								{formatInt(e.numTickets)}
							</Table.Cell>
							<Table.Cell column="numGuests" oneLine numeric>
								{formatInt(e.numGuests)}
							</Table.Cell>
							<Table.Cell column="revenue" oneLine numeric>
								{formatMoney(e.revenue, { areCents: true })}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>

				<Table.Footer>
					<Table.Row>
						<Table.Cell column="user">Total</Table.Cell>
						<Table.Cell column="numTickets" oneLine numeric>
							{formatInt(totals.totalTickets)}
						</Table.Cell>
						<Table.Cell column="numGuests" oneLine numeric>
							{formatInt(totals.totalGuests)}
						</Table.Cell>
						<Table.Cell column="revenue" oneLine numeric>
							{formatMoney(totals.totalRevenue, { areCents: true })}
						</Table.Cell>
					</Table.Row>
				</Table.Footer>
			</Table>

			<Table.Toolbar>
				<a href={fileURL} download={file.name}>
					Download
				</a>
			</Table.Toolbar>
		</Table.Container>
	);
}

type DataPoint = {
	numTickets: number;
	numGuests: number;
	revenue: number;
};

function calculateTotals(points: DataPoint[]) {
	let totalTickets = 0;
	let totalGuests = 0;
	let totalRevenue = 0;
	for (let i = 0; i < points.length; i++) {
		const point = points[i];
		totalTickets += point.numTickets;
		totalGuests += point.numGuests;
		totalRevenue += point.revenue;
	}
	return { totalTickets, totalGuests, totalRevenue };
}

type MarketEntranceStationDetailPoint =
	MarketEntryTicketAggregateByMarketEntranceStationDetailReportQuery["marketEntryTicketAggregateByMarketEntranceStationDetailReport"]["points"][0];

const emptyMarketEntranceStationDetailPoints: MarketEntranceStationDetailPoint[] = [];

type MarketEntranceStationDetailColumn = "groupName" | "unitPrice" | "quantity" | "revenue";

const marketEntranceStationDetailColumns: MarketEntranceStationDetailColumn[] = [
	"groupName",
	"unitPrice",
	"quantity",
	"revenue",
];
const marketEntranceStationDetailDownloadColumns: (keyof MarketEntranceStationDetailPoint)[] = [
	"marketEntranceID",
	"marketEntranceName",
	"marketEntranceStationID",
	"marketEntranceStationName",
	"regularCarPrice",
	"seniorCarPrice",
	"regularBikePrice",
	"seniorBikePrice",
	"childPassengerPrice",
	"regularPassengerPrice",
	"seniorPassengerPrice",
	"childWalkinPrice",
	"regularWalkinPrice",
	"seniorWalkinPrice",
	"numRegularCars",
	"numSeniorCars",
	"numVeteranCars",
	"numRegularBikes",
	"numSeniorBikes",
	"numVeteranBikes",
	"numChildPassengers",
	"numRegularPassengers",
	"numSeniorPassengers",
	"numChildWalkins",
	"numRegularWalkins",
	"numSeniorWalkins",
	"numVeteranWalkins",
	"numMonthlyParkingPasses",
	"regularCarsRevenue",
	"seniorCarsRevenue",
	"veteranCarsRevenue",
	"regularBikesRevenue",
	"seniorBikesRevenue",
	"veteranBikesRevenue",
	"childPassengersRevenue",
	"regularPassengersRevenue",
	"seniorPassengersRevenue",
	"childWalkinsRevenue",
	"regularWalkinsRevenue",
	"seniorWalkinsRevenue",
	"veteranWalkinsRevenue",
];

type DailyRegisterReportByMarketEntranceStationDetailProps = {
	date: string | null;
};

function DailyRegisterReportByMarketEntranceStationDetail({
	date,
}: DailyRegisterReportByMarketEntranceStationDetailProps) {
	const currentDate = toLocalDateString(new Date());
	const { loading, data, error } = useMarketEntryTicketAggregateByMarketEntranceStationDetailReportQuery({
		variables: { date: date ?? currentDate },
	});

	const points =
		data?.marketEntryTicketAggregateByMarketEntranceStationDetailReport.points ??
		emptyMarketEntranceStationDetailPoints;

	const file = React.useMemo(
		() =>
			toCSV(
				`daily-register-report-by-market-entrance-station-detail${date ? "-" + date.split("T")[0] : ""}`,
				points,
				marketEntranceStationDetailDownloadColumns,
			),
		[date, points],
	);
	const fileURL = URL.createObjectURL(file.data);

	if (loading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>There was a problem loading this report.</div>;
	}

	if (!points) {
		return <div>No data to display.</div>;
	}

	return (
		<Table.Container className="hub__index-page__table__container">
			<Table columns={marketEntranceStationDetailColumns}>
				<Table.Header className="hub__index-page__table__header">
					<Table.Row>
						<Table.Heading column="groupName">Group</Table.Heading>
						<Table.Heading column="unitPrice">Price</Table.Heading>
						<Table.Heading column="quantity">Quantity</Table.Heading>
						<Table.Heading column="revenue">Revenue</Table.Heading>
					</Table.Row>
				</Table.Header>

				<Table.Body className="hub__index-page__table__body">
					{points.map((e) => (
						<React.Fragment key={e.marketEntranceStationID}>
							<Table.Row style={{ backgroundColor: "#f4f4f4" }}>
								<Table.Cell
									column="groupName"
									oneLine
									colspan={marketEntranceStationDetailColumns.length}
									style={{ fontSize: "1.5rem", fontWeight: "bold" }}
								>
									{e.marketEntranceName}: {e.marketEntranceStationName}
								</Table.Cell>
							</Table.Row>

							<DailyRegisterReportByMarketEntranceStationDetailRows point={e} />
						</React.Fragment>
					))}
				</Table.Body>
			</Table>

			<Table.Toolbar>
				<a href={fileURL} download={file.name}>
					Download
				</a>
			</Table.Toolbar>
		</Table.Container>
	);
}

type DailyRegisterReportByMarketEntranceStationDetailPrintProps = {
	date: string | null;
};

function DailyRegisterReportByMarketEntranceStationDetailPrint({
	date,
}: DailyRegisterReportByMarketEntranceStationDetailPrintProps) {
	const currentDate = toLocalDateString(new Date());
	const { loading, data, error } = useMarketEntryTicketAggregateByMarketEntranceStationDetailReportQuery({
		variables: { date: date ?? currentDate },
	});

	const points =
		data?.marketEntryTicketAggregateByMarketEntranceStationDetailReport.points ??
		emptyMarketEntranceStationDetailPoints;

	if (loading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>There was a problem loading this report.</div>;
	}

	if (!points) {
		return <div>No data to display.</div>;
	}

	return (
		<div>
			{points.map((e, i) => (
				<React.Fragment key={e.marketEntranceStationID}>
					{i !== 0 && <PrintPageBreak />}

					<Heading level={1} style={{ marginTop: "2rem" }}>
						{e.marketEntranceName}: {e.marketEntranceStationName}
					</Heading>

					<Paragraph style={{ fontSize: "1.5rem" }}>Daily Register Report</Paragraph>

					<Paragraph style={{ fontSize: "1.5rem" }}>
						Report Date: {formatDateTime(stringToLocalDate(date ?? currentDate, "day"), "ddd, MMMM Do, YYYY")}
					</Paragraph>

					<Table.Container className="hub__index-page__table__container">
						<Table columns={marketEntranceStationDetailColumns}>
							<Table.Header className="hub__index-page__table__header">
								<Table.Row>
									<Table.Heading column="groupName">Group</Table.Heading>
									<Table.Heading column="unitPrice">Price</Table.Heading>
									<Table.Heading column="quantity">Quantity</Table.Heading>
									<Table.Heading column="revenue">Revenue</Table.Heading>
								</Table.Row>
							</Table.Header>

							<Table.Body className="hub__index-page__table__body">
								<DailyRegisterReportByMarketEntranceStationDetailRows point={e} />
							</Table.Body>
						</Table>
					</Table.Container>
				</React.Fragment>
			))}
		</div>
	);
}

type DailyRegisterReportByMarketEntranceStationDetailRowsProps = {
	point: MarketEntranceStationDetailPoint;
};

function DailyRegisterReportByMarketEntranceStationDetailRows({
	point,
}: DailyRegisterReportByMarketEntranceStationDetailRowsProps) {
	return (
		<React.Fragment>
			<If condition={point.numRegularCars > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Car
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.regularCarPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numRegularCars)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.regularCarsRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numSeniorCars > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Car (Senior)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.seniorCarPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numSeniorCars)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.seniorCarsRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numVeteranCars > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Car (Veteran)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.seniorCarPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numVeteranCars)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.veteranCarsRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numRegularBikes > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Bike
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.regularBikePrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numRegularBikes)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.regularBikesRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numSeniorBikes > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Bike (Senior)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.seniorBikePrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numSeniorBikes)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.seniorBikesRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numVeteranBikes > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Bike (Veteran)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.seniorBikePrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numVeteranBikes)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.veteranBikesRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numChildPassengers > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Passenger (Child)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.childPassengerPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numChildPassengers)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.childPassengersRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numRegularPassengers > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Passenger
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.regularPassengerPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numRegularPassengers)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.regularPassengersRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numSeniorPassengers > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Passenger (Senior)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.seniorPassengerPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numSeniorPassengers)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.seniorPassengersRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numChildWalkins > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Walkin (Child)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.childWalkinPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numChildWalkins)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.childWalkinsRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numRegularWalkins > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Walkin
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.regularWalkinPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numRegularWalkins)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.regularWalkinsRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numSeniorWalkins > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Walkin (Senior)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.seniorWalkinPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numSeniorWalkins)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.seniorWalkinsRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numVeteranWalkins > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Walkin (Veteran)
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(point.seniorWalkinPrice, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numVeteranWalkins)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(point.veteranWalkinsRevenue, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<If condition={point.numMonthlyParkingPasses > 0}>
				<Table.Row>
					<Table.Cell column="groupName" oneLine>
						Monthly Parking Pass
					</Table.Cell>
					<Table.Cell column="unitPrice" oneLine numeric>
						{formatMoney(0, { areCents: true })}
					</Table.Cell>
					<Table.Cell column="quantity" oneLine numeric>
						{formatInt(point.numMonthlyParkingPasses)}
					</Table.Cell>
					<Table.Cell column="revenue" oneLine numeric>
						{formatMoney(0, { areCents: true })}
					</Table.Cell>
				</Table.Row>
			</If>

			<Table.Row style={{ fontWeight: "bold", borderTop: "1px solid #e8e8e8" }}>
				<Table.Cell column="groupName" oneLine>
					Total
				</Table.Cell>
				<Table.Cell column="quantity" oneLine numeric>
					{formatInt(
						point.numRegularCars +
							point.numSeniorCars +
							point.numVeteranCars +
							point.numRegularBikes +
							point.numSeniorBikes +
							point.numVeteranBikes +
							point.numChildPassengers +
							point.numRegularPassengers +
							point.numSeniorPassengers +
							point.numChildWalkins +
							point.numRegularWalkins +
							point.numSeniorWalkins +
							point.numVeteranWalkins +
							point.numMonthlyParkingPasses,
					)}
				</Table.Cell>
				<Table.Cell column="revenue" oneLine numeric>
					{formatMoney(
						point.regularCarsRevenue +
							point.seniorCarsRevenue +
							point.veteranCarsRevenue +
							point.regularBikesRevenue +
							point.seniorBikesRevenue +
							point.veteranBikesRevenue +
							point.childPassengersRevenue +
							point.regularPassengersRevenue +
							point.seniorPassengersRevenue +
							point.childWalkinsRevenue +
							point.regularWalkinsRevenue +
							point.seniorWalkinsRevenue +
							point.veteranWalkinsRevenue,
						{ areCents: true },
					)}
				</Table.Cell>
			</Table.Row>
		</React.Fragment>
	);
}
