import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const marketEntranceStationHubPageInfo = newPageInfo({
	title: "Market Entrance Stations",
	description: "Pages related to the Market Entrance Station model.",
	exact: false,
	to: hubsPageInfo.to + "/market-entrance-stations",
	path: hubsPageInfo.path + "/market-entrance-stations",
	parent: hubsPageInfo,
});
