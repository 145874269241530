import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { categoricalColorSchemes, defaultLineProps } from "@hex-insights/charts";
import {
	addTimeToDate,
	Conditional,
	dateTrunc,
	Else,
	formatDateTime,
	getObjectValues,
	Heading,
	If,
	isAfter,
	preventDefault,
	Row,
	Section,
	TimeUnit,
	useLazyRef,
} from "@hex-insights/core";
import { FormType, RadioButtonsInput, RadioField, useFormState } from "@hex-insights/forms";
import {
	MarketEntryTicketTimelineDayComparisonReportQuery,
	useMarketEntryTicketTimelineDayComparisonReportQuery,
	Weekday,
} from "@hex-insights/roadium.shared";
import {
	formatAbbreviatedIntTick,
	formatAbbreviatedMoneyTick,
	formatFloatTick,
	formatIntTick,
	formatMoneyTick,
	formatTimeOnlyLabel,
	formatTimeOnlyTick,
	TicketReportMetric,
	ticketReportMetricOptions,
} from "../../../Utilities";
import styles from "../RecentTicketsChart/styles.module.css";

const intervalString = "20 minutes";
const intervalDayJS: [number, TimeUnit] = [20, "minutes"];
const pollInterval = 5 * 60 * 1000;

type DayComparisonFormValues = {
	metric: TicketReportMetric;
};

const initialFormValues: DayComparisonFormValues = {
	metric: "tickets",
};

export function DayComparison() {
	const formState = useFormState({ initialFormValues, formType: FormType.Standing });

	const initialTimestampsRef = useLazyRef(getTimeBounds);
	const { loading, data, error } = useMarketEntryTicketTimelineDayComparisonReportQuery({
		variables: {
			...initialTimestampsRef.current,
			interval: intervalString,
		},
		pollInterval,
	});
	const mergedData = React.useMemo(() => mergeTimelines(data), [data]);

	const { metric } = formState.formValues;
	const formatYTick = metric === "revenue" ? formatAbbreviatedMoneyTick : formatAbbreviatedIntTick;
	const tooltipFormatter = metric === "revenue" ? tooltipMoneyFormatter : tooltipNumberFormatter;

	return (
		<Section className="tile">
			<Section.Header className="tile__header">
				<Row justify="space-between">
					<Heading level={2} noMargin>
						Today's Overview
					</Heading>

					<form noValidate onSubmit={preventDefault}>
						<RadioField
							formState={formState}
							name="metric"
							label=""
							options={ticketReportMetricOptions}
							Input={RadioButtonsInput}
							noClear
							className={styles["recent-tickets-chart__form__metric"]}
						/>
					</form>
				</Row>
			</Section.Header>

			<Section.Body>
				<div style={{ width: "100%", height: "60vh" }}>
					<Conditional>
						<If condition={loading}>Loading...</If>
						<If condition={!!error}>There was a problem loading this report.</If>
						<Else>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart data={mergedData[metric]}>
									<XAxis dataKey="createdAt" tickFormatter={formatTimeOnlyTick} />
									<YAxis tickFormatter={formatYTick} />
									<Tooltip
										isAnimationActive={false}
										labelFormatter={tooltipLabelFormatter}
										formatter={tooltipFormatter}
									/>

									<Line
										{...defaultLineProps}
										stroke={categoricalColorSchemes.tableau10[0]}
										strokeWidth={2}
										dataKey="current"
									/>
									<Line {...defaultLineProps} stroke={categoricalColorSchemes.tableau10[1]} dataKey="lastWeek" />
									<Line
										{...defaultLineProps}
										stroke={categoricalColorSchemes.tableau10[2]}
										dataKey="averageLast4Weeks"
									/>
									<Line
										{...defaultLineProps}
										stroke={categoricalColorSchemes.tableau10[3]}
										dataKey="averageLast16Weeks"
									/>
								</LineChart>
							</ResponsiveContainer>
						</Else>
					</Conditional>
				</div>
			</Section.Body>
		</Section>
	);
}

function getTimeBounds() {
	const today = new Date();

	const startTimeToday = dateTrunc(today, "day");
	const endTimeToday = addTimeToDate(dateTrunc(addTimeToDate(today, [1, "day"]), "day"), [-1, "millisecond"]);
	const startTimeWeekAgo = dateTrunc(addTimeToDate(today, [-7, "days"]), "day");
	const endTimeWeekAgo = addTimeToDate(dateTrunc(addTimeToDate(today, [-6, "days"]), "day"), [-1, "millisecond"]);
	const startTime4WeeksAgo = dateTrunc(addTimeToDate(today, [-28, "days"]), "day");
	const startTime16WeeksAgo = dateTrunc(addTimeToDate(today, [-112, "days"]), "day");
	const endTimeYesterday = addTimeToDate(dateTrunc(today, "day"), [-1, "millisecond"]);
	return {
		startTimeToday: startTimeToday.toISOString(),
		endTimeToday: endTimeToday.toISOString(),
		startTimeWeekAgo: startTimeWeekAgo.toISOString(),
		endTimeWeekAgo: endTimeWeekAgo.toISOString(),
		startTime4WeeksAgo: startTime4WeeksAgo.toISOString(),
		startTime16WeeksAgo: startTime16WeeksAgo.toISOString(),
		endTimeYesterday: endTimeYesterday.toISOString(),
		weekday: getWeekFilter(today),
	};
}

function getWeekFilter(today: Date) {
	switch (today.getDay()) {
		case 0:
			return Weekday.Sunday;
		case 1:
			return Weekday.Monday;
		case 2:
			return Weekday.Tuesday;
		case 3:
			return Weekday.Wednesday;
		case 4:
			return Weekday.Thursday;
		case 5:
			return Weekday.Friday;
		case 6:
			return Weekday.Saturday;
		default:
			return Weekday.Sunday;
	}
}

type MergedTimelineData = {
	[K in TicketReportMetric]: MergedTimelinePoint[];
};

type TimelineDayComparisonReportKey = "current" | "lastWeek" | "averageLast4Weeks" | "averageLast16Weeks";

type MergedTimelinePoint = {
	createdAt: string;
} & {
	[K in TimelineDayComparisonReportKey]: number;
};

function mergeTimelines(data?: MarketEntryTicketTimelineDayComparisonReportQuery) {
	if (!data) {
		return [];
	}

	const mergedGuests: Record<string, MergedTimelinePoint> = {};
	const mergedTickets: Record<string, MergedTimelinePoint> = {};
	const mergedRevenue: Record<string, MergedTimelinePoint> = {};

	const now = new Date();

	const keys: TimelineDayComparisonReportKey[] = ["current", "lastWeek", "averageLast4Weeks", "averageLast16Weeks"];
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		const timeline = data[key].timeline;
		for (let j = 0; j < timeline.length; j++) {
			const point = timeline[j];
			const pointKey = toTimeOnlyString(point.createdAt);
			if (!mergedGuests[pointKey]) {
				mergedGuests[pointKey] = { createdAt: point.createdAt } as MergedTimelinePoint;
			}
			if (!mergedTickets[pointKey]) {
				mergedTickets[pointKey] = { createdAt: point.createdAt } as MergedTimelinePoint;
			}
			if (!mergedRevenue[pointKey]) {
				mergedRevenue[pointKey] = { createdAt: point.createdAt } as MergedTimelinePoint;
			}

			if (key === "current" && isAfter(point.createdAt, now)) {
				continue;
			}

			mergedGuests[pointKey][key] = point.numGuests;
			mergedTickets[pointKey][key] = point.numTickets;
			mergedRevenue[pointKey][key] = point.revenue;
		}
	}

	const merged: MergedTimelineData = {
		guests: getObjectValues(mergedGuests),
		tickets: getObjectValues(mergedTickets),
		revenue: getObjectValues(mergedRevenue),
	};

	return merged;
}

function toTimeOnlyString(dt: string) {
	// localizes times to handle DST differences between dates
	return formatDateTime(dt, "HH:mm");
}

function tooltipLabelFormatter(label: string) {
	const endBound = addTimeToDate(new Date(label), intervalDayJS);
	return `${formatTimeOnlyLabel(label)} to ${formatTimeOnlyLabel(endBound.toISOString())}`;
}

function tooltipNumberFormatter(value: number, name: string) {
	switch (name) {
		case "current":
		case "lastWeek":
			return [formatIntTick(value), formatReportKeyName(name)];
		case "averageLast4Weeks":
		case "averageLast16Weeks":
			return [formatFloatTick(value), formatReportKeyName(name)];
	}
	return [formatIntTick(value), formatReportKeyName(name)];
}

function tooltipMoneyFormatter(value: number, name: string) {
	return [formatMoneyTick(value), formatReportKeyName(name)];
}

function formatReportKeyName(name: string) {
	const dow = formatDateTime(new Date(), "dddd");
	switch (name) {
		case "current":
			return "Today";
		case "lastWeek":
			return `Last ${dow}`;
		case "averageLast4Weeks":
			return `Avg ${dow} (Last 4 Weeks)`;
		case "averageLast16Weeks":
			return `Avg ${dow} (Last 16 Weeks)`;
		default:
			return name;
	}
}
