import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const marketEntranceHubPageInfo = newPageInfo({
	title: "Market Entrances",
	description: "Pages related to the Market Entrance model.",
	exact: false,
	to: hubsPageInfo.to + "/market-entrances",
	path: hubsPageInfo.path + "/market-entrances",
	parent: hubsPageInfo,
});
