import { ChildrenProps, Heading, List, Paragraph } from "@hex-insights/core";
import { InternalLink, InternalLinkProps, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../Components";
import { holidayHubPageInfo } from "../Holiday/pageinfo";
import { marketEntranceHubPageInfo } from "../MarketEntrance/pageinfo";
import { marketEntrancePricingStructureHubPageInfo } from "../MarketEntrancePricingStructure/pageinfo";
import { marketEntranceStationHubPageInfo } from "../MarketEntranceStation/pageinfo";
import { marketEntryTicketHubPageInfo } from "../MarketEntryTicket/pageinfo";
import { permissionHubPageInfo } from "../Permission/pageinfo";
import { permissionGroupHubPageInfo } from "../PermissionGroup/pageinfo";
import { userHubPageInfo } from "../User/pageinfo";
import { hubsIndexPageInfo } from "./pageinfo";
import styles from "./styles.module.css";

export function HubsIndexPage() {
	useActivePageRegistration(hubsIndexPageInfo);

	return (
		<Main>
			<Heading level={1}>Hubs</Heading>

			<List className={styles["hub-list"]}>
				<HubListItem name="Market Entrances" to={marketEntranceHubPageInfo.to}>
					Market entrance locations.
				</HubListItem>

				<HubListItem name="Market Entrance Pricing Structures" to={marketEntrancePricingStructureHubPageInfo.to}>
					Set pricing on market entrances.
				</HubListItem>

				<HubListItem name="Market Entrance Stations" to={marketEntranceStationHubPageInfo.to}>
					Individual lanes, gates, or doorways that are part of a market entrance.
				</HubListItem>

				<HubListItem name="Market Entry Tickets" to={marketEntryTicketHubPageInfo.to}>
					Records of guest entry into the market.
				</HubListItem>

				<HubListItem name="Holidays" to={holidayHubPageInfo.to}>
					Recording for holidays at the market that affect pricing.
				</HubListItem>

				<HubListItem name="Users" to={userHubPageInfo.to}>
					Users of this system. Including this application and the ticket entry app.
				</HubListItem>

				<HubListItem name="Permission Groups" to={permissionGroupHubPageInfo.to}>
					Groups that connect users to permissions.
				</HubListItem>

				<HubListItem name="Permissions" to={permissionHubPageInfo.to}>
					Access controls for users.
				</HubListItem>
			</List>
		</Main>
	);
}

type HubListItemProps = {
	name: string;
	to: InternalLinkProps["to"];
} & ChildrenProps;

function HubListItem({ name, to, children }: HubListItemProps) {
	return (
		<List.Item className={styles["hub-list__item"]}>
			<InternalLink to={to} className={styles["hub-list__item__link"]}>
				{name}
			</InternalLink>
			<Paragraph className={styles["hub-list__item__description"]}>{children}</Paragraph>
		</List.Item>
	);
}
