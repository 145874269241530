import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const hubsPageInfo = newPageInfo({
	title: "Hubs",
	description: "Directory for hubs.",
	exact: false,
	to: "/hubs",
	path: "/hubs",
	parent: rootPageInfo,
});
