// Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment.
import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../../root.pageinfo";

export const loginPageInfo = newPageInfo({
	title: "Login",
	description: "Login to access this site.",
	exact: false,
	isLeaf: true,
	path: "/",
	to: "/",
	parent: rootPageInfo,
	noSearch: true,
});
