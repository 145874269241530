import React from "react";
import { ChildrenProps, ClassNameProps, Column, makeClassName, StyleProps } from "@hex-insights/core";
import styles from "./styles.module.css";

export type LabeledValueProps = {
	isLoading?: boolean;
	label: React.ReactNode;
	scale?: number;
} & Partial<ClassNameProps> &
	Partial<StyleProps> &
	ChildrenProps;

export function LabeledValue({ isLoading = false, label, scale = 1, className, style, children }: LabeledValueProps) {
	return (
		<Column
			align="center"
			className={makeClassName(styles["labeled-value"], className)}
			style={{ ...style, "--labeled-value---scale": scale } as React.CSSProperties}
		>
			<span className={styles["labeled-value__label"]}>{label}</span>
			<span className={styles["labeled-value__value"]}>{isLoading ? "Loading..." : children}</span>
		</Column>
	);
}
