import { newPageInfo } from "@hex-insights/router";
import { userHubPageInfo } from "../pageinfo";

export const userHubIndexPageInfo = newPageInfo({
	name: "Users Index",
	title: "",
	description: "Index of Users.",
	exact: true,
	to: userHubPageInfo.to,
	path: userHubPageInfo.path,
	parent: userHubPageInfo,
});
