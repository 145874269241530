import { newPageInfo } from "@hex-insights/router";
import { hubsPageInfo } from "../pageinfo";

export const hubsIndexPageInfo = newPageInfo({
	name: "Hubs Index",
	title: "",
	description: "Index page for hubs.",
	exact: true,
	to: "/hubs",
	path: "/hubs",
	parent: hubsPageInfo,
});
