import { ChildrenProps, ClassNameProps, makeClassName, StyleProps } from "@hex-insights/core";
import { useScrollReadyOnMount } from "@hex-insights/router";
import styles from "./styles.module.css";

export type MainProps = {
	/** HTML `id` of the element. */
	id?: string;
} & Partial<ClassNameProps> &
	Partial<StyleProps> &
	Partial<ChildrenProps>;

/**
 * Renders an HTML `<main>` element with a default `id` property.
 */
export function Main({ id = "main", className, style, children }: MainProps) {
	useScrollReadyOnMount();

	return (
		<main id={id} className={makeClassName(styles["main"], "scroll-target", className)} style={style}>
			{children}
		</main>
	);
}
