import React from "react";
import { useApolloClient } from "@apollo/client";
import { Authentication } from "@hex-insights/app-modules";
import { ChildrenProps, filterUnique, pickKeys } from "@hex-insights/core";
import * as Log from "@hex-insights/log";
import {
	User,
	ViewerWithPermissionsDocument,
	ViewerWithPermissionsQuery,
	ViewerWithPermissionsQueryVariables,
} from "@hex-insights/roadium.shared";

export type AuthUser = Pick<User, "id" | "email" | "status">;

export const AuthenticationContext = Authentication.createAuthenticationContext<AuthUser>();

export type AuthenticationManagerProps = ChildrenProps;

export function AuthenticationManager({ children }: AuthenticationManagerProps) {
	const client = useApolloClient();

	const getAuthData = React.useCallback(async () => {
		try {
			const { data } = await client.query<ViewerWithPermissionsQuery, ViewerWithPermissionsQueryVariables>({
				query: ViewerWithPermissionsDocument,
				fetchPolicy: "network-only",
			});
			const userData = data?.viewer ?? null;

			if (userData === null) {
				return { user: null, permissions: [] };
			}

			const user = pickKeys(userData, ["id", "email", "status"]);
			const permissions = filterUnique(
				userData.permissionGroups.reduce((a, e) => a.concat(e.permissions.map((n) => n.name)), [] as string[]),
			);
			return { user, permissions };
		} catch (err) {
			return { user: null, permissions: [] };
		}
	}, [client]);

	const contextValue = Authentication.useAuthenticationManager(getAuthData);

	React.useEffect(() => {
		Log.setField("userID", contextValue.user?.id ?? null);
	}, [contextValue.user]);

	return <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>;
}
