import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const homePageInfo = newPageInfo({
	name: "Home",
	title: "Home",
	description: "Home page.",
	exact: true,
	to: "/",
	path: "/",
	parent: rootPageInfo,
});
