// Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment.
import { useApolloClient } from "@apollo/client";
import { Authentication } from "@hex-insights/app-modules";
import { Column, Conditional, Else, Heading, If, Paragraph } from "@hex-insights/core";
import { serverRequest } from "@hex-insights/roadium.shared";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../Components";
import { AuthenticationContext } from "../../../Contexts";
import { loginPageInfo } from "../Login/pageinfo";
import { logoutPageInfo } from "./pageinfo";
import styles from "../authentication.module.css";

export function LogoutPage() {
	useActivePageRegistration(logoutPageInfo);

	const client = useApolloClient();
	const { isLoading } = Authentication.useLogout({
		AuthenticationContext,
		requestLogout,
		onSuccess: client.resetStore,
	});

	return (
		<Main className={styles["auth-page__main"]}>
			<Column align="center" className={styles["auth-page__content-container"]}>
				<Heading.H1>Logout</Heading.H1>

				<Conditional>
					<If condition={isLoading}>
						<Paragraph>Logging out...</Paragraph>
					</If>

					<Else>
						<Paragraph>Logged out.</Paragraph>

						<Paragraph>
							<InternalLink to={loginPageInfo.to}>Click to log back in</InternalLink>
						</Paragraph>
					</Else>
				</Conditional>
			</Column>
		</Main>
	);
}

function requestLogout() {
	return serverRequest("/auth/logout", { method: "POST" });
}
