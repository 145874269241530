import React from "react";
import { Conditional, Else, Heading, If, useSync } from "@hex-insights/core";
import {
	MarketEntrancePricingStructureForm,
	useMarketEntrancePricingStructureDetailQuery,
} from "@hex-insights/roadium.shared";
import { useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { marketEntrancePricingStructureHubDetailPageInfo } from "./pageinfo";

export function MarketEntrancePricingStructureHubDetailPage() {
	const { marketEntrancePricingStructureID } = marketEntrancePricingStructureHubDetailPageInfo.useParams();

	const { loading, data, error } = useMarketEntrancePricingStructureDetailQuery({
		variables: { id: marketEntrancePricingStructureID },
	});
	const { syncID, synchronize } = useSync();

	const marketEntrancePricingStructure = data?.marketEntrancePricingStructure;

	useActivePageRegistration(
		marketEntrancePricingStructureHubDetailPageInfo,
		!!marketEntrancePricingStructure
			? marketEntrancePricingStructureHubDetailPageInfo.title(marketEntrancePricingStructure)
			: "",
	);

	return (
		<Main>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={error !== undefined}>Error loading marketEntrancePricingStructure</If>
				<Else>
					{!!marketEntrancePricingStructure && (
						<React.Fragment>
							<Heading level={1}>{marketEntrancePricingStructure.id}</Heading>

							<MarketEntrancePricingStructureForm.ControlledDetail
								key={syncID}
								marketEntrancePricingStructure={marketEntrancePricingStructure}
								onSuccess={synchronize}
							/>
						</React.Fragment>
					)}
				</Else>
			</Conditional>
		</Main>
	);
}
