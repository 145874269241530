import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { HolidayHubCreatePage } from "./CreatePage";
import { holidayHubCreatePageInfo } from "./CreatePage/pageinfo";
import { HolidayHubDetailPage } from "./DetailPage";
import { holidayHubDetailPageInfo } from "./DetailPage/pageinfo";
import { HolidayHubIndexPage } from "./IndexPage";
import { holidayHubIndexPageInfo } from "./IndexPage/pageinfo";
import { holidayHubPageInfo } from "./pageinfo";

export function HolidayHub() {
	useActivePageRegistration(holidayHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...holidayHubIndexPageInfo.routeProps}>
				<HolidayHubIndexPage />
			</Route>
			<Route {...holidayHubCreatePageInfo.routeProps}>
				<HolidayHubCreatePage />
			</Route>
			<Route {...holidayHubDetailPageInfo.routeProps}>
				<HolidayHubDetailPage key={holidayHubDetailPageInfo.useKey()} />
			</Route>
		</PageRouteSwitch>
	);
}
