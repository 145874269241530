import React from "react";
import { Heading } from "@hex-insights/core";
import { HolidayForm } from "@hex-insights/roadium.shared";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { Main } from "../../../../Components";
import { holidayHubDetailPageInfo } from "../DetailPage/pageinfo";
import { holidayHubCreatePageInfo } from "./pageinfo";

export function HolidayHubCreatePage() {
	useActivePageRegistration(holidayHubCreatePageInfo);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback<HolidayForm.ControlledCreateProps["onSuccess"]>(
		({ id }) => {
			pushHistory(holidayHubDetailPageInfo.to(id));
		},
		[pushHistory],
	);

	return (
		<Main>
			<Heading level={1}>New Holiday</Heading>

			<HolidayForm.ControlledCreate onSuccess={onSuccess} />
		</Main>
	);
}
