import React from "react";
import {
	Button,
	ChildrenProps,
	ErrorBoundary,
	EscapeListenerManager,
	GlobalStackingManager,
	Heading,
	If,
	Modal,
	Paragraph,
	Row,
	ScrollLockManager,
	useCount,
} from "@hex-insights/core";
import * as Log from "@hex-insights/log";

export type GlobalErrorBoundaryProps = ChildrenProps;

export function GlobalErrorBoundary({ children }: GlobalErrorBoundaryProps) {
	const { count: key, increment: incrementKey } = useCount();

	const errorDisplay = <GlobalErrorDisplay onReload={incrementKey} />;

	return (
		<ErrorBoundary key={key} errorDisplay={errorDisplay} onError={onGlobalError}>
			{children}
		</ErrorBoundary>
	);
}

function onGlobalError(error: Error, errorInfo: React.ErrorInfo) {
	Log.withFields({
		error: {
			name: error.name,
			message: error.message,
			componentStack: errorInfo.componentStack,
		},
		url: window.location.href,
	}).fatal("Application crashed");
}

type GlobalErrorDisplayProps = {
	onReload: () => void;
};

function GlobalErrorDisplay({ onReload }: GlobalErrorDisplayProps) {
	const instanceID = Log.getFields().instanceID;
	const isOnHomePage = window.location.pathname === "/";

	const onReturnToHome = React.useCallback(() => {
		window.location.href = "/";
		onReload();
	}, [onReload]);

	return (
		<EscapeListenerManager>
			<GlobalStackingManager>
				<ScrollLockManager>
					<Modal className="global-error-boundary">
						<Modal.Header>
							<Heading level={1} noMargin>
								Error
							</Heading>
						</Modal.Header>

						<Modal.Body>
							<Paragraph>Something's gone wrong.</Paragraph>

							<If condition={!!instanceID}>
								<Paragraph>
									We record all crashes including information on when and where they happen. If this issue persists,
									contact us with the identifier:
								</Paragraph>

								<Paragraph>
									<code>{instanceID}</code>
								</Paragraph>
							</If>
						</Modal.Body>

						<Modal.Footer>
							<Row justify="spaced-end">
								<If condition={!isOnHomePage}>
									<Button variant="secondary" onClick={onReturnToHome}>
										Return to Home
									</Button>
								</If>

								<Button variant="primary" onClick={onReload}>
									Reload
								</Button>
							</Row>
						</Modal.Footer>
					</Modal>
				</ScrollLockManager>
			</GlobalStackingManager>
		</EscapeListenerManager>
	);
}
