// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { HubsDetailRedirectPage } from "./DetailRedirectPage";
import { hubsDetailRedirectPageInfo } from "./DetailRedirectPage/pageinfo";
import { HolidayHub } from "./Holiday";
import { holidayHubPageInfo } from "./Holiday/pageinfo";
import { HubsIndexPage } from "./IndexPage";
import { hubsIndexPageInfo } from "./IndexPage/pageinfo";
import { MarketEntranceHub } from "./MarketEntrance";
import { marketEntranceHubPageInfo } from "./MarketEntrance/pageinfo";
import { MarketEntrancePricingStructureHub } from "./MarketEntrancePricingStructure";
import { marketEntrancePricingStructureHubPageInfo } from "./MarketEntrancePricingStructure/pageinfo";
import { MarketEntranceStationHub } from "./MarketEntranceStation";
import { marketEntranceStationHubPageInfo } from "./MarketEntranceStation/pageinfo";
import { MarketEntryTicketHub } from "./MarketEntryTicket";
import { marketEntryTicketHubPageInfo } from "./MarketEntryTicket/pageinfo";
import { hubsPageInfo } from "./pageinfo";
import { PermissionHub } from "./Permission";
import { permissionHubPageInfo } from "./Permission/pageinfo";
import { PermissionGroupHub } from "./PermissionGroup";
import { permissionGroupHubPageInfo } from "./PermissionGroup/pageinfo";
import { UserHub } from "./User";
import { userHubPageInfo } from "./User/pageinfo";
import "./styles.css";

export function HubsPage() {
	useActivePageRegistration(hubsPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...hubsIndexPageInfo.routeProps}>
				<HubsIndexPage />
			</Route>
			<Route {...holidayHubPageInfo.routeProps}>
				<HolidayHub />
			</Route>
			<Route {...marketEntranceHubPageInfo.routeProps}>
				<MarketEntranceHub />
			</Route>
			<Route {...marketEntrancePricingStructureHubPageInfo.routeProps}>
				<MarketEntrancePricingStructureHub />
			</Route>
			<Route {...marketEntranceStationHubPageInfo.routeProps}>
				<MarketEntranceStationHub />
			</Route>
			<Route {...marketEntryTicketHubPageInfo.routeProps}>
				<MarketEntryTicketHub />
			</Route>
			<Route {...permissionHubPageInfo.routeProps}>
				<PermissionHub />
			</Route>
			<Route {...permissionGroupHubPageInfo.routeProps}>
				<PermissionGroupHub />
			</Route>
			<Route {...userHubPageInfo.routeProps}>
				<UserHub />
			</Route>
			<Route {...hubsDetailRedirectPageInfo.routeProps}>
				<HubsDetailRedirectPage />
			</Route>
		</PageRouteSwitch>
	);
}
