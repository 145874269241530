import { User } from "@hex-insights/roadium.shared";
import { newPageInfo } from "@hex-insights/router";
import { userHubDetailPageInfo } from "../pageinfo";

export const userHubDetailAuthQRCodePageInfo = newPageInfo({
	name: "User Detail Authentication QR Code page",
	title: "Authentication QR Code",
	description: "Authentication QR Code for a user.",
	exact: true,
	to: (id: User["id"]) => userHubDetailPageInfo.to(id) + "/auth-qr-code",
	path: userHubDetailPageInfo.path + "/auth-qr-code",
	parent: userHubDetailPageInfo,
	paramsType: userHubDetailPageInfo.paramsType,
});
