import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, Conditional, Else, Heading, If, mappedArray, Row } from "@hex-insights/core";
import {
	MarketEntranceLink,
	MarketEntranceStationLink,
	MarketEntryTicketFilterForm,
	MarketEntryTicketFilterFormState,
	MarketEntryTicketFilterFormUtils,
	MarketEntryTicketFormat,
	MarketEntryTicketIndexQuery,
	MarketEntryTicketOrderField,
	MarketEntryTicketOrderForm,
	MarketEntryTicketOrderFormState,
	MarketEntryTicketOrderFormValues,
	MarketEntryTicketPaginationForm,
	useMarketEntryTicketIndexQuery,
} from "@hex-insights/roadium.shared";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Table } from "@hex-insights/tables";
import { Main } from "../../../../Components";
import { marketEntryTicketHubCreatePageInfo } from "../CreatePage/pageinfo";
import { marketEntryTicketHubDetailPageInfo } from "../DetailPage/pageinfo";
import { marketEntryTicketHubIndexPageInfo } from "./pageinfo";

const columns: (
	| keyof MarketEntryTicketIndexQuery["marketEntryTicketConnection"]["edges"][0]["node"]
	| keyof MarketEntryTicketIndexQuery["marketEntryTicketConnection"]["edges"][0]["node"]["marketEntranceStation"]
)[] = [
	"id",
	"createdAt",
	"marketEntrance",
	"marketEntranceStation",
	"vehicleType",
	"numChildGuests",
	"numRegularGuests",
	"numSeniorGuests",
	"isMonthlyParkingPassApplied",
	"isSeniorDiscountApplied",
	"isVeteranDiscountApplied",
	"amountPaid",
	"wasRefunded",
];

const columnWidths = {
	createdAt: "minmax(14rem, 1fr)",
};

function orderFieldToColumn(field: MarketEntryTicketOrderField | null) {
	if (field === null) {
		return "id";
	}
	return MarketEntryTicketOrderFormValues.orderFieldToModelField[field];
}

function columnToOrderField(column: string) {
	if (column === "id") {
		return null;
	}
	return MarketEntryTicketOrderFormValues.modelFieldToOrderField[column];
}

export function MarketEntryTicketHubIndexPage() {
	useActivePageRegistration(marketEntryTicketHubIndexPageInfo);

	const filterFormState = MarketEntryTicketFilterFormState.useFilterFormStateWithQueryStateSync();
	const orderFormState = MarketEntryTicketOrderFormState.useOrderFormStateWithQueryStateSync();
	const paginationFormState = IndexForms.usePaginationFormStateWithQueryStateAndLocalStorageSync();

	const filterInputs = IndexForms.useFilterInput(
		filterFormState.formValues,
		MarketEntryTicketFilterFormUtils.toFilterInputs,
	);
	const orderInput = IndexForms.useOrderInput(orderFormState.formValues);
	const queryVariables = IndexForms.useQueryVariables(
		filterInputs,
		orderInput,
		paginationFormState.formValues,
		paginationFormState.formSetFunctions.cursor,
	);
	const { loading, data, error } = useMarketEntryTicketIndexQuery({ variables: queryVariables });

	const clearFilters = React.useCallback(() => {
		MarketEntryTicketFilterFormUtils.clearFilters(filterFormState);
	}, [filterFormState]);

	const numActiveFilters = MarketEntryTicketFilterFormUtils.countActiveFilters(filterFormState.formValues);
	const [paginationInfo, setPaginationInfo] = IndexForms.usePaginationInfo();

	const tableSortingProps = IndexForms.useTableSortingFromOrderForm(
		orderFormState,
		orderFieldToColumn,
		columnToOrderField,
	);

	const numFillerRows = Math.max(
		(paginationFormState.formValues.resultsPerPage ?? 0) - (data?.marketEntryTicketConnection.edges.length ?? 0),
		0,
	);

	return (
		<Main>
			<Row justify="space-between" align="center" style={{ margin: "var(--heading--level-1---margin)" }}>
				<Heading level={1} noMargin>
					Market Entry Tickets
				</Heading>

				<InternalLink to={marketEntryTicketHubCreatePageInfo.to}>New Market Entry Ticket</InternalLink>
			</Row>

			<Row
				justify="spaced-start"
				align="center"
				horizontalSpacing="0.25rem"
				withVerticalSpacing
				overflow="wrap"
				style={{ marginBottom: "0.75rem" }}
			>
				<MarketEntryTicketFilterForm.FilterController formState={filterFormState} numActiveFilters={numActiveFilters} />
				<If condition={numActiveFilters > 0}>
					<Button variant="link" size="small" onClick={clearFilters}>
						Clear
					</Button>
				</If>
				<MarketEntryTicketOrderForm.OrderController formState={orderFormState} />
			</Row>

			<Table.Container className="hub__index-page__table__container">
				<Table
					columns={columns as any}
					columnWidths={columnWidths}
					minColumnWidth="10rem"
					scrollSyncID={paginationFormState.formValues.cursor}
					{...tableSortingProps}
					className="hub__index-page__table"
				>
					<Table.Header className="hub__index-page__table__header">
						<Table.Row>
							<Table.Heading column="id">ID</Table.Heading>
							<Table.Heading column="createdAt">Created At</Table.Heading>
							<Table.Heading column="marketEntrance" noSort>
								Entrance
							</Table.Heading>
							<Table.Heading column="marketEntranceStation" noSort>
								Station
							</Table.Heading>
							<Table.Heading column="vehicleType">Vehicle Type</Table.Heading>
							<Table.Heading column="numChildGuests">Num Child Guests</Table.Heading>
							<Table.Heading column="numRegularGuests">Num Regular Guests</Table.Heading>
							<Table.Heading column="numSeniorGuests">Num Senior Guests</Table.Heading>
							<Table.Heading column="isMonthlyParkingPassApplied">Monthly Parking Pass</Table.Heading>
							<Table.Heading column="isSeniorDiscountApplied">Senior Discount</Table.Heading>
							<Table.Heading column="isVeteranDiscountApplied">Veteran Discount</Table.Heading>
							<Table.Heading column="amountPaid">Amount Paid</Table.Heading>
							<Table.Heading column="wasRefunded">Was Refunded</Table.Heading>
						</Table.Row>
					</Table.Header>

					<Table.Body className="hub__index-page__table__body">
						<Conditional>
							<If condition={loading}>
								<Table.Row className="hub__index-page__table__row">
									<Table.Cell column="id" colspan={columns.length}>
										Loading...
									</Table.Cell>
								</Table.Row>
								{mappedArray((paginationFormState.formValues.resultsPerPage ?? 1) - 1, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</If>

							<Else>
								{data?.marketEntryTicketConnection.edges.map((e) => (
									<Table.Row key={e.node.id} className="hub__index-page__table__row">
										<Table.Cell column="id" oneLine>
											<InternalLink to={marketEntryTicketHubDetailPageInfo.to(e.node.id)}>{e.node.id}</InternalLink>
										</Table.Cell>
										<Table.Cell column="createdAt" oneLine>
											{MarketEntryTicketFormat.Fields.createdAtShort(e.node.createdAt)}
										</Table.Cell>
										<Table.Cell column="marketEntrance" oneLine>
											<MarketEntranceLink instance={e.node.marketEntranceStation.marketEntrance} />
										</Table.Cell>
										<Table.Cell column="marketEntranceStation" oneLine>
											<MarketEntranceStationLink instance={e.node.marketEntranceStation} />
										</Table.Cell>
										<Table.Cell column="vehicleType" oneLine>
											{MarketEntryTicketFormat.Fields.vehicleType(e.node.vehicleType)}
										</Table.Cell>
										<Table.Cell column="numChildGuests" oneLine numeric>
											{e.node.numChildGuests}
										</Table.Cell>
										<Table.Cell column="numRegularGuests" oneLine numeric>
											{e.node.numRegularGuests}
										</Table.Cell>
										<Table.Cell column="numSeniorGuests" oneLine numeric>
											{e.node.numSeniorGuests}
										</Table.Cell>
										<Table.Cell column="isMonthlyParkingPassApplied" oneLine>
											{e.node.isMonthlyParkingPassApplied ? "Yes" : "No"}
										</Table.Cell>
										<Table.Cell column="isSeniorDiscountApplied" oneLine>
											{e.node.isSeniorDiscountApplied ? "Yes" : "No"}
										</Table.Cell>
										<Table.Cell column="isVeteranDiscountApplied" oneLine>
											{e.node.isVeteranDiscountApplied ? "Yes" : "No"}
										</Table.Cell>
										<Table.Cell column="amountPaid" oneLine numeric>
											{MarketEntryTicketFormat.Fields.amountPaid(e.node.amountPaid)}
										</Table.Cell>
										<Table.Cell column="wasRefunded" oneLine>
											{e.node.wasRefunded ? "Yes" : "No"}
										</Table.Cell>
									</Table.Row>
								))}
								{mappedArray(numFillerRows, (i) => (
									<Table.FillerRow
										key={i}
										className="hub__index-page__table__row hub__index-page__table__row--filler"
									/>
								))}
							</Else>
						</Conditional>
					</Table.Body>
				</Table>

				<Table.Toolbar>
					<Row justify="space-between" align="center">
						<MarketEntryTicketPaginationForm.PaginationForm
							formState={paginationFormState}
							filters={filterInputs}
							order={orderInput}
							setPaginationInfo={setPaginationInfo}
						/>

						<span>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<If condition={!!error}>Something went wrong.</If>
								<If condition={data?.marketEntryTicketConnection.edges.length === 0}>No results found.</If>
								<Else>{IndexForms.getPaginationDescription(paginationInfo)}</Else>
							</Conditional>
						</span>
					</Row>
				</Table.Toolbar>
			</Table.Container>
		</Main>
	);
}
